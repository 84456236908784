import React from "react";
import Card from "./Card";
import { Grid } from "@material-ui/core";

export default function Cards({ data }) {
  const [removals, setRemovals] = React.useState(0);
  const [locals, setLocals] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  React.useEffect(() => {
    if (data) {
      setLocals(data.locals.length);
      setRemovals(data.removals.length);
      setTotal(
        data.materials.length
          ? data.materials.reduce(
              (ac, material) => ac + material.quantity * 1,
              0
            )
          : 0
      );
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6}>
        <Card
          title="RETIROS COMPLETOS"
          value={removals}
          background={"#2E2E2E"}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Card
          title="SEDES / SUCURSALES"
          value={locals}
          background={"#2E2E2E"}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <Card title="KILOS RECICLADOS" value={total} background={"#2E2E2E"} />
      </Grid>
    </Grid>
  );
}
