import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import regProvComsData from "../../../../resources/regProvCom";
import dispositionsActions from "../../../../redux/actions/dispositions";

import General from "./General";
import Map from "../Shared/Map";

import materialsList from "../../../../resources/materials";
const style = {
   maxWidth:"md",
  };
const initState = {
  name: "",
  rut: "",
  contactName: "",
  email: "",
  phone: "",
  address: "",
  position: {
    latitude: -33.436988,
    longitude: -70.634391
  },
  commune: "13114",
  region: "",
  province: "",
  typeOfTreatment: [],
  materialsID: [],
};

const initRegProvCom = {
  name: "Las Condes",
  code: "13114",
  regCode: "CL-RM",
  provCode: "Santiago",
}

// const initTypeOfTreatment = {
//   name: "",
//   code: "",
//   subTypeName: "",
//   subTypeCode: "",
// }

const initTreatmentType = "TOT_ELI"

function EditModal({ typeOfTreatments }) {
  const dispatch = useDispatch();
  const dispositions = useSelector((state) => state.dispositions);

  const [error, setError] = useState(false);
  const [communes] = useState([]);
  const [formData, setFormData] = useState(initState);
  const [dispositionData, setDispositionData] = useState({});

  const [communeSelected, setCommuneSelected] = useState(initRegProvCom);
  const [typesOfTreatmentsSelected, setTypesOfTreatmentsSelected] = useState([]);
  const [treatmentType, setTreatmentType] = useState(initTreatmentType);
  const [typeOfTreatmentsOptions, setTypeOfTreatmentsOptions] = useState([]);
  const [materialsSelected, setMaterialsSelected] = useState([]);

  const materials = materialsList.materials;

  // REGIONES PROVINCIAS Y COMUNAS
  useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionsElements.provincias.forEach(provinceElement => {
        provinceElement.comunas.forEach(communeElement => {
          communes.push({
            name: communeElement.name,
            code: communeElement.code,
            regCode: regionsElements.region_iso_3166_2,
            provCode: provinceElement.name,
          })
        })
      })
    })
    communes.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    })
  }, [])

  useEffect(() => {
    setDispositionData(dispositions.dataModal);
    if (dispositions.dataModal) {
      dispositions.dataModal.typeOfTreatment.forEach(element => {
        if (element.subTypeName === "Valorización") {
          setTreatmentType("TOT_VAL")
        } else {
          setTreatmentType("TOT_ELI")
        }
        typesOfTreatmentsSelected.push({
          name: element.name,
          code: element.code,
          subTypeName: element.subTypeName,
          subTypeCode: element.subTypeCode,
        })

      });

      setCommuneSelected({
        name: communes.filter(commune => commune.code === dispositions.dataModal.commune)[0].name,
        code: dispositions.dataModal.commune,
        regCode: dispositions.dataModal.region,
        provCode: dispositions.dataModal.province,
      })

      Object.values(materials).map((material) => {
        dispositions.dataModal.materialsID.map((materialID) => {
          if (material.key.toUpperCase() === materialID) {
            materialsSelected.push({
              name: material.name,
              code: material.key.toUpperCase()
            })
          }
        })
      })

    }
  }, [dispositions.dataModal]);

  useEffect(() => {
    if (treatmentType === "TOT_ELI") {
      setTypeOfTreatmentsOptions(typeOfTreatments.filter(typeOfTreatment => typeOfTreatment.subTypeName === "Eliminación" && typeOfTreatment.estado === "1"))
    } else {
      setTypeOfTreatmentsOptions(typeOfTreatments.filter(typeOfTreatment => typeOfTreatment.subTypeName === "Valorización" && typeOfTreatment.estado === "1"))
    }
  }, [treatmentType])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const updateData = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const handleCloseModal = () => {
    resetFormData();
    dispatch(dispositionsActions.setEditModal(false));
    dispatch(dispositionsActions.setDataModal(null));
  };

  const resetFormData = () => {
    setTypesOfTreatmentsSelected([])
    setMaterialsSelected([]);
    setFormData(initState);
  }

  const handleUpdate = () => {
    if (
      formData.name &&
      formData.rut &&
      formData.contactName &&
      formData.address &&
      formData.position &&
      formData.commune &&
      formData.region &&
      formData.province &&
      formData.typeOfTreatment &&
      formData.materialsID
    ) {
      dispatch(dispositionsActions.updateDisposition(formData));
      // dispatch(dispositionsActions.setEditModal(false));
      resetFormData();
      dispatch(dispositionsActions.setEditModal(false));
      dispatch(dispositionsActions.setDataModal(null));
    } else {
      alert("Faltan campos por completar");
      setError(true);
    }
  };

  return (
    <Dialog open={dispositions.editModal} onClose={handleCloseModal} scroll="body" maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>
              Actualizar información de la planta de disposición final de <strong>{dispositions.dataModal?.name?.toUpperCase()}</strong>
            </h4>
          </Grid>
          <hr></hr>
          <Grid item xs={6}>
            <General
              dispositionData={dispositionData}
              // setDispositionData={dispositionData}
              formData={formData}
              setFormData={setFormData}
              updateData={updateData}
              communes={communes}
              communeSelected={communeSelected}
              setCommuneSelected={setCommuneSelected}
              treatmentType={treatmentType}
              setTreatmentType={setTreatmentType}
              typeOfTreatmentsOptions={typeOfTreatmentsOptions}

              typesOfTreatmentsSelected={typesOfTreatmentsSelected}
              setTypesOfTreatmentsSelected={setTypesOfTreatmentsSelected}

              materialsSelected={materialsSelected}
              setMaterialsSelected={setMaterialsSelected}
              error={error}
            />
          </Grid>
          <Grid item xs={6}>
            <Map formData={formData} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="secondary"
            onClick={handleCloseModal}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleUpdate()}
            color="primary"
            startIcon={<CheckIcon />}
          >
            Actualizar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;