import axios from "axios";
import notifiesActions from "./notifies";

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "TRANSPORTERS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "TRANSPORTERS_SET_DELETE_MODAL", payload: value });
};

const fetchTransporters = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/transporters");
      dispatch({ type: "TRANSPORTERS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return false;
    }
  };
};

const postTransporter = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/adminTool/transporters", payload);
      dispatch(notifiesActions.enqueueNotify("TRANSPORTER_CREATE_SUCCESS"));
      dispatch(fetchTransporters());
      return response.data;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("TRANSPORT_CREATE_FAIL"));
      return false;
    }
  };
};

const deleteTransporter = ({transporterID}) => {
  return async (dispatch) => {
    try {
      //await axios.delete("/adminTool/transporters?transporterId=" + transporterId);
	  await axios.delete("/adminTool/transporters?transporterID=" + transporterID);
      dispatch(notifiesActions.enqueueNotify("TRANSPORTER_DELETE_SUCCESS"));
      dispatch(fetchTransporters());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("TRANSPORTER_DELETE_FAIL"));
      return false;
    }
  };
};

const updateTransporter = (payload) => {
  return async (dispatch) => {
    try {
      await axios.patch("/adminTool/transporters", payload);
      dispatch(notifiesActions.enqueueNotify("TRANSPORTER_UPDATE_SUCCESS"));
      dispatch(fetchTransporters());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("TRANSPORTER_UPDATE_FAIL"));
      return false;
    }
  };
};

export default {
  setCreateModal,
  setDeleteModal,
  fetchTransporters,
  postTransporter,
  deleteTransporter,
  updateTransporter,
};