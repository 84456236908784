import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TableVehicles from "./TableVehicles";
import EditVehicle from "./EditVehicle";
import functions from "../../../../resources/functions";

const initState = {
  licensePlate: "",
  brand: "",
  model: "",
  type: "",
  description: "",
  maxVolume: 0,
  resolutions: [],
  // resolutionsURL: [],
}

const resolutions = [
  { name: 'Residuos Peligrosos', code: 'RP' },
  { name: 'Residuos No Peligrosos', code: 'RNP' },
];

export default function Vehicles({
  vehiclesFormData,
  setVehiclesFormData,
  error,
  setError,
  formDataVehicleSelected,
  setFormDataVehicleSelected,
}) {
  const [addVehicle, setAddVehicle] = useState(false);
  const [status, setStatus] = useState(0);  // 0 incorrect form, 1 correct form
  const [vehicleData, setVehicleData] = useState(initState);  // new vehicle to array
  const [isVehicleSelected, setIsVehicleSelected] = useState("");
  const [vehicleSelectedData, setVehicleSelectedData] = useState(initState); // vehicle to edit
  const [autocompleteResolutions, setAutocompleteResolutions] = useState([]);
  const [isEditVehicle, setIsEditVehicle] = useState(false);
  const [isAddVehicle, setIsAddVehicle] = useState(false);

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };
  const style2 = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "number",
    InputLabelProps: {
      shrink: true,
    },
  };

  useEffect(() => {
    if (
      // vehicleData.brand === "" ||
      // vehicleData.type === "" ||
      vehicleData.licensePlate === ""
    ) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (isVehicleSelected) {
      const auxVehicleSelected = vehiclesFormData.find((vehicleElement) => vehicleElement._id === isVehicleSelected.toString());
      setVehicleSelectedData(auxVehicleSelected);
    }
  }, [isVehicleSelected])

  const resetFormData = () => {
    setAutocompleteResolutions([]);
    setVehicleData(initState);
    setAddVehicle(false);
  }

  const updateData = (data, name) => {
    setVehicleData({ ...vehicleData, [name]: data });
  };

  const addVehicleToArray = () => {
    let aux = [];
    vehiclesFormData.forEach((vehicleElement) => {
      aux.push(vehicleElement);
    });
    aux.push(vehicleData);
    setVehiclesFormData(aux);
    resetFormData();
  };

  // const deleteVehicle = (key) => {
  //   let aux = [];
  //   vehiclesFormData.forEach((vehicleElement, i) => {
  //     if (parseInt(i, 0) !== parseInt(key, 0)) {
  //       aux.push(vehicleElement);
  //     }
  //   });
  //   setVehiclesFormData(aux);
  // };

  const formatLicensePlate = (licensePlate) => {
    return functions.formatLicensePlate(licensePlate);
  };

  const handleResolutions = (k) => {
    setAutocompleteResolutions(k);
    setVehicleData({
      ...vehicleData, resolutions: k.map(element => {
        return element.code
      })
    });
  };

  const handleEdit = () => {
    setIsEditVehicle(true);
    setIsAddVehicle(false);
  };

  const handleAdd = () => {
    setIsEditVehicle(false);
    setIsAddVehicle(true);
  };

  function Item(params) {
    return (
      <div>
        <TextField
          label="Resolución"
          error={error && !vehicleData.resolutions}
          {...style}
          {...params}
        />
      </div>
    );
  };

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos vehículos</h5>
        </Grid>
        {/*  ---- feature to implement ---- */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={handleAdd}
              color="primary"
              variant={isAddVehicle ? "outlined" : "contained"}
              style={{ color: isAddVehicle ? "grey" : "white" }}
            >
              Agregar nuevo vehículo
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleEdit}
              color="primary"
              variant={isEditVehicle ? "outlined" : "contained"}
              style={{ color: isEditVehicle ? "grey" : "white" }}
            >
              Editar vehículo
            </Button>
          </Grid>
        </Grid>
        {isEditVehicle &&
          <Grid item xs={12}>
            <EditVehicle
              vehiclesFormData={vehiclesFormData}
              isVehicleSelected={isVehicleSelected}
              setIsVehicleSelected={setIsVehicleSelected}
              vehicleSelectedData={vehicleSelectedData}
              resolutions={resolutions}
              setStatus={setStatus}
              formDataVehicleSelected={formDataVehicleSelected}
              setFormDataVehicleSelected={setFormDataVehicleSelected}
            />
          </Grid>
        }
        {/* component add vehicles */}
        {isAddVehicle &&
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                color={addVehicle ? "primary" : "secondary"}
                onClick={addVehicle ? () => resetFormData() : () => setAddVehicle(!addVehicle)}
                variant={addVehicle ? "outlined" : "text"}
              >
                {addVehicle ? "- Descartar vehículo" : "+ Agregar vehículo"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              {addVehicle && (
                <Box textAlign='right'>
                  <Button
                    color="secondary"
                    variant={status ? "outlined" : "contained"}
                    style={{ color: status ? "#00C851" : "white" }}
                    onClick={status ? () => addVehicleToArray() : () => setError(true)}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Agregar al listado
              </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        }
        {addVehicle && (
          <React.Fragment>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Marca"
                value={vehicleData.brand}
                onChange={(event) => updateData(event.target.value, "brand")}
              // error={error && !formDataVehicles.brand}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Modelo"
                value={vehicleData.model}
                onChange={(event) => updateData(event.target.value, "model")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Placa patente"
                value={formatLicensePlate(vehicleData.licensePlate)}
                onChange={(event) => updateData(event.target.value, "licensePlate")}
                error={error && !vehicleData.licensePlate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Tipo"
                value={vehicleData.type}
                onChange={(event) => updateData(event.target.value, "type")}
              // error={error && !formDataVehicles.type}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
			  
                {...style2}
				
                label="Máximo Volumen"
                value={vehicleData.maxVolume}
                onChange={(event) => updateData(event.target.value, "maxVolume")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                {...style}
                label="Unidades"
                value={vehicleData.typeUnit}
                onChange={(event) => updateData(event.target.value, "typeUnit")}
                name="type"
                select
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
              >
                <MenuItem value="litros">Litros</MenuItem>
                  <MenuItem value="m3">M3</MenuItem>
                  <MenuItem value="toneladas">Toneladas</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="resolutions-select"
                options={resolutions}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={autocompleteResolutions}
                renderInput={(params) => Item(params)}
                onChange={(_, k) => handleResolutions(k)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...style}
                label="Descripción"
                multiline
                rows={4}
                value={vehicleData.description}
                onChange={(event) => updateData(event.target.value, "description")}
              />
            </Grid>
          </React.Fragment>
        )}
        <hr></hr>
        <Grid item xs={12}>
          <TableVehicles
            vehiclesFormData={vehiclesFormData}
          // setVehiclesFormData={setVehiclesFormData}
          // deleteVehicle={deleteVehicle}
          // resolutions={resolutions}
          />
        </Grid>
      </Grid>
    </div>
  );
}


