import React, { useEffect, useState } from "react";
import Axios from "../../../../api/Axios";
import { TextField, Grid, MenuItem, Typography, Chip, formatMs } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import Jimp from "jimp";
import { DropzoneArea } from "material-ui-dropzone";

const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

 async function goVoucher(voucher) {
    // window.open();
    //window.location.href = voucher;
	var win = window.open();
    win.document.write('<iframe src="' + voucher  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
	//win.document.write('<a href="' + voucher  + '" download="voucher.jpg"></a>');
	
  }

export default function General({ formData, setFormData }) {
  const utils = useSelector((state) => state.utils);

  const [fileValue, setFileValue] = useState("");
  const [pdfError, setPdfError] = useState(false);
  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [autocompleteVehicle, setAutocompleteVehicle] = useState({});
  const [autocompleteValues, setAutocompleteValues] = useState({
    local: "",
    transporter: "",
    disposition: "",
  });
  
  /*var imagenes= formData.urlVoucher.split("','");
  console.log(imagenes[1]);*/
  
  useEffect(() => {
    if (formData.localID || formData.transporterID) {
      setAutocompleteValues({
        ...autocompleteValues,
        local: utils.locals.find((local) => local._id === formData.localID),
        transporter: utils.transporters.find((transporter) => transporter._id === formData.transporterID)
      });
    }

  }, [formData.localID, formData.transporterID]);

  useEffect(() => {
    if (utils.transporters) {
      utils.transporters.map((transporter) => {
        transporter.vehiclesID.map((vehicle) => {
          if (vehicle._id === formData.vehicleID) {
            setAutocompleteVehicle(vehicle);
          }
        })
      })
    }
  }, [utils.transporters])

  useEffect(() => {
    if (formData.transporterID) {
		if(formData.transporterID[0].vehiclesID){
			setVehiclesOptions(utils.transporters.filter(transporter => transporter._id === formData.transporterID)[0].vehiclesID)
		}
      
    }
  }, [])

  const translateAuthor = (author) => {
    if (author === "ADMIN") {
      return "admin";
    }
    if (author === "WEBAPP_SUSCRIPTION") {
      return "usr susc";
    }
    if (author === "WEBAPP_EXTRA") {
      return "usr extra";
    }
  };

  const handleChangeTransporter = (k) => {
    setFormData({ ...formData, transporterID: k._id });
    // setAutocompleteVehicle({});
    // setFormData({ ...formData, vehicleID: null });
    setVehiclesOptions(utils.transporters.filter(transporter => transporter._id === k._id)[0].vehiclesID);
  };

  const handleVehicle = (k) => {
    setAutocompleteVehicle(k);
    setFormData({ ...formData, vehicleID: k._id })
  };

  function handleLoadFile(e) {
    var files = e.target.files;
    var file = null;
    if (files[0].type === "application/pdf") {
      let fileReader = new FileReader();
      setFileValue(e.target.value);
      setPdfError(false);
      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        setFormData({ ...formData, file });
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      setFileValue(null);
      setPdfError(true);
    }
  };

  async function handleLoadFileVoucher1(e) {
    console.log(formData);
    var files = e.target.files;
    var file = null;
    console.log(files);
    console.log(files[0]);
    console.log(files[0].type);

    const dat = new FormData();
    dat.append('file',files)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    let response = await Axios.post("/adminTool/removalsImages", dat, config);
    console.log(response);

  };

  const handleLoadFileVoucher = (files) => {
    console.log("handleLoadFileVoucher1");
    console.log(files);
    Array.from(files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
                // let response = Axios.post("/adminTool/removalsImages", { ...formData });
                // console.log(response);
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  

  function setDateIn00(date) {
    const aux = new Date(date);
    aux.setHours(0);
    aux.setMinutes(0);
    aux.setSeconds(1);
    setFormData({
      ...formData,
      datetimeRemoval: aux,
    });
  };

  function ItemDisposition(params) {
    return (
      <TextField
        {...params}
        margin="dense"
        label="Planta de dispisición final"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };

  function ItemVehicles(params) {
    return (
      <TextField
        {...params}
        margin="dense"
        label="Placa patente"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">General</Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Chip
          label={formData.author ? translateAuthor(formData.author) : "admin"}
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          options={utils.locals}
          value={autocompleteValues.local}
          disableClearable
          getOptionLabel={(option) => option ? option.customerID?.brand + " " + option.name : "Selecciona una sede / sucursal"}
          renderInput={(params) => (
            <TextField
              margin="dense"
              {...params}
              label="Sede / Sucursal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(e, k) => setFormData({ ...formData, localID: k._id })}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            {...style}
            label="Fecha solicitud"
            inputVariant="outlined"
            value={formData.datetimeRequest}
            onChange={(date) =>
              setFormData({ ...formData, datetimeRequest: date })
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          options={utils.transporters}
          value={autocompleteValues.transporter}
          disableClearable
          getOptionLabel={(option) => option ? option.name + " " + option.lastName : "Selecciona un transportista"}
          renderInput={(params) => (
            <TextField
              margin="dense"
              {...params}
              label="Transportista"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(_, k) => handleChangeTransporter(k)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            {...style}
            label="Fecha de retiro"
            inputVariant="outlined"
            value={formData.datetimeRemoval}
            onChange={(date) => setDateIn00(date)}
            disabled={!formData.transporterID}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        {vehiclesOptions &&
          <Autocomplete
            {...style}
            options={vehiclesOptions}
            key={vehiclesOptions}
            disableClearable
            getOptionLabel={(option) => option.licensePlate ? option.licensePlate : "Selecciona una patente"}
            getOptionSelected={(option, value) => option === value}
            value={autocompleteVehicle}
            disabled={!formData.transporterID}
            onChange={(_, k) => handleVehicle(k)}
            renderInput={(params) => ItemVehicles(params)}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
        <Typography variant="h6">Horario</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          {...style}
          label="Ventana de tiempo"
          value={formData.window}
          select
          onChange={(e) => setFormData({ ...formData, window: e.target.value })}
          disabled={!formData.transporterID}
        >
          <MenuItem value="A">A (9:00 a 13:00)</MenuItem>
          <MenuItem value="B">B (14:00 a 19:00)</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          {...style}
          type="number"
          label="Pago transportista"
          value={formData.payment}
          disabled={!formData.localID || !formData.transporterID}
          onChange={(e) =>
            setFormData({
              ...formData,
              payment: e.target.value > 0 && e.target.value,
            })
          }
          InputProps={{
            startAdornment: <AttachMoneyIcon />,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          {...style}
          label="Reporte"
          value={formData.report ? formData.report : fileValue}
          onChange={handleLoadFile}
          type={formData.report ? "text" : "file"}
          disabled={!formData.localID || !formData.transporterID}
          accept="pdf"
          error={pdfError}
          helperText={
            pdfError
              ? "Debe ser un archivo pdf. El archivo será descartado."
              : ""
          }
          onClick={formData.report ? () => window.open(formData.report) : null}
        />
      </Grid>
      {
		  /* <Grid item xs={12} sm={3}>
        <TextField
          {...style}
          label="Voucher"
          value={formData.voucher ? formData.voucher : fileValue}
          onChange={handleLoadFileVoucher1}
          type={formData.voucher ? "text" : "file"}
          disabled={!formData.localID || !formData.transporterID}
          accept="pdf"
          error={pdfError}
          helperText={
            pdfError
              ? "Debe ser una imagen. El archivo será descartado."
              : ""
          }
          onClick={formData.voucher ? () => window.open(formData.voucher) : null}
        />
      </Grid> 
	  version anterior
	  <TextField
                {...style}
                label="Voucher (* Haz click para descargar)"
                value={formData.urlVoucher}
                type="text"
                //onClick={formData.urlVoucher ? () => window.open(formData.urlVoucher) : null}
              />
              <img
                src={formData.urlVoucher}
                style={{ maxWidth: "100%" }}
                alt=""
                onClick={formData.urlVoucher ? () => goVoucher(formData.urlVoucher) : null}
              />
	  
	  
	  */
	  }

        {formData.urlVoucher!=null ?

          <Grid item xs={12} sm={6}>
			
              {formData.urlVoucher.map((urlVoucher) => 
				
				<Grid item xs={12} sm={6}>
				
				  <img
					src={urlVoucher}
					style={{ width: "100%" }}
					alt="voucher"
					onClick={urlVoucher ? () => goVoucher(urlVoucher) : null}
				  />
			  </Grid>
			  )
			  }
             
          </Grid>
          :
          <Grid item xs={12} sm={12}>
            <DropzoneArea
              showPreviewsInDropzone={true}
              dropzoneText={
                "Arrastra la imagen del voucher o has click aquí examinar"
              }
              classes={{ minHeight: 0 }}
              onChange={handleLoadFileVoucher}
              acceptedFiles={["image/*"]}
              filesLimit={1}
              style={{ height: 100 }}
              showAlerts={false}
            />
          </Grid>
          }
    </Grid>
  );
}
