import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import dispositionsActions from "../../../../redux/actions/dispositions";

export default function Index() {
  const dispatch = useDispatch();

  const dispositions = useSelector((state) => state.dispositions);
  
  const [data, setData] = useState({ name: "" });

  useEffect(() => {
    if (dispositions.dataModal) {
      setData({
        ...data,
        name: dispositions.dataModal?.name,
      });
    }
  }, [dispositions.dataModal]);

  const handleDelete = async () => {
    dispatch(dispositionsActions.deleteDisposition({ dispositionID: dispositions.dataModal._id }));
    handleClose();
  };

  const handleClose = async () => {
    dispatch(dispositionsActions.setDeleteModal(false));
    dispatch(dispositionsActions.setDataModal(null));
  };

  return (
    <Dialog
      open={dispositions.deleteModal}
      onClose={() => handleClose()}
      scroll={"body"}
    >
      <DialogContent>
        <Typography variant="h5">Eliminar planta de disposición final</Typography>
        <hr />
        <Typography variant="body1">
          ¿Seguro que quieres eliminar esta planta de disposición final de <strong>{data.name.toUpperCase()}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button color="secondary" onClick={() => handleClose()}>
            No
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Sí
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
