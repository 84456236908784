const defaultState = {
  removals: [],
  count: 0,
  cards: {
    completes: 0,
    incompletes: 0,
    extras: 0,
    suscription: 0,
    sensors: 0,
  },
  search: {
    type: "ALL",
    query: {},
  },
  selector: "COMPLETE",
  createModal: false,
  viewExtraModal: false,
  deleteModal: false,
  dataModal: null,
  dataExtraModal : null,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REMOVALS_FETCH": {
      return { ...state, removals: payload };
    }
    case "REMOVALS_COUNT": {
      return { ...state, count: payload.count };
    }
    case "REMOVALS_SET_SELECTOR": {
      return { ...state, selector: payload };
    }
    case "REMOVALS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REMOVALS_SET_VIEW_EXTRA_MODAL": {
      return { ...state, viewExtraModal: payload }
    }
    case "REMOVALS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "REMOVALS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "REMOVALS_SET_DATA_EXTRA_MODAL": {
      return { ...state, dataExtraModal: payload };
    }
    case "REMOVALS_SET_SEARCH": {
      return { ...state, search: payload };
    }
    case "REMOVALS_SET_CARDS": {
      return { ...state, cards: payload };
    }
    default:
      return state;
  }
}

export default reducer;
