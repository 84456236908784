import React, { Fragment, useEffect } from "react";
import DialogLogin from "./DialogLogin";
import LoginForm from "./LoginForm";
import { useSelector, useDispatch } from "react-redux";

export default function Login() {
  // console.log("INDEX");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.loginStatus.step === "WELCOME") {
      setTimeout(() => {
        dispatch({
          type: "AUTH_SET_LOGIN_STATUS",
          payload: {
            error: false,
            step: "READY",
            message: "",
          },
        });
      }, 3000);
    }
  }, [auth.loginStatus.step]);
  return (
    <Fragment>
      {auth.loged === "LOGED" && auth.loginStatus.step === "WELCOME" && (
        <DialogLogin />
      )}
      {auth.loged === "NOT_LOGED" && <LoginForm />}
    </Fragment>
  );
}
