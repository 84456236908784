import React from "react";
import { Typography } from "@material-ui/core";
import {
    SignalWifi0Bar,
    SignalWifi1Bar,
    SignalWifi2Bar,
    SignalWifi3Bar,
    SignalWifi4Bar,
} from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Rssi({ rssi }) {
    return (
        //Validación nueva
        <Typography variant="body2" color="textSecondary">
            {rssi <= 25 ? (
                <SignalWifi0Bar style={{ color: "#00C851" }} />
            ) : rssi >= 26 && rssi <= 50 ? (
                <SignalWifi1Bar style={{ color: "#00C851" }} />
            ) : rssi >= 51 && rssi <= 75 ? (
                <SignalWifi2Bar style={{ color: "#00C851" }} />
            ) : rssi > 76 && rssi <= 90 ? (
                <SignalWifi3Bar style={{ color: "#00C851" }} />
            ) : (
                <SignalWifi4Bar style={{ color: "#00C851" }} />
            )}
            {" " + rssi + "%"}
        </Typography>
    );
}

Rssi.propTypes = {
    rssi: PropTypes.number.isRequired,
};
