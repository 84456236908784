import React from "react";
import Axios from "../api/Axios";
import { useAppContext } from "./AppContext";
const CustomersContext = React.createContext();

export function CustomersContextProvider(props) {
  const { setLoading } = useAppContext();
  const [viewModalCreateCustomer, setViewModalCreateCustomer] = React.useState(
    false
  );
  const [viewModalCreateLocal, setViewModalCreateLocal] = React.useState(false);
  const [viewModalEditLocal, setViewModalEditLocal] = React.useState(false);
  const [viewModalEditCustomer, setViewModalEditCustomer] = React.useState(
    false
  );
  const [viewCustomer, setViewCustomer] = React.useState(false);
  const [viewDelete, setViewDelete] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [locals, setLocals] = React.useState([]);
  const [select, setSelect] = React.useState("CUSTOMER");
  const [data, setData] = React.useState(null);
  const [suscriptions, setSuscriptions] = React.useState([]);
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    getSuscriptionsFunction({ setSuscriptions, setLoading });
    if (select === "CUSTOMER") {
      getCustomersFunction({ setCustomers });
    } else {
      getLocalsFunction({ setLocals, setLoading });
    }
  }, [select]);

  React.useEffect(() => {
    if (reload) {
      if (select === "CUSTOMER") {
        getCustomersFunction({ setCustomers, setLoading });
      } else {
        getCustomersFunction({ setCustomers, setLoading });
        getLocalsFunction({ setLocals, setLoading });
      }
      setReload(false);
    }
  }, [reload]);

  const createCustomer = async (data) => {
    await createCustomerFunction(data);
    setReload(true);
  };

  const createLocal = async (data) => {
    await createLocalFunction(data);
    setReload(true);
  }

  const deleteCustomer = async (customerID) => {
    await deleteCustomerFunction({ customerID });
    setReload(true);
  };

  const deleteLocal = async (localID) => {
    await deleteLocalFunction({ localID });
    setReload(true);
  };

  const updateLocal = async ( data ) => {
    await updateLocalFunction( data );
    setReload(true);
  }
  const updateCustomer = async ( data ) => {
    await updateCustomerFunction( data );
    setReload(true);
  }

  const value = React.useMemo(() => {
    return {
      viewModalCreateCustomer,
      setViewModalCreateCustomer,
      viewModalCreateLocal,
      setViewModalCreateLocal,
      viewModalEditLocal,
      setViewModalEditLocal,
      viewModalEditCustomer,
      setViewModalEditCustomer,
      viewCustomer,
      setViewCustomer,
      viewDelete,
      setViewDelete,
      customers,
      setCustomers,
      deleteCustomer,
      deleteLocal,
      updateLocal,
	  updateCustomer,		
      locals,
      setLocals,
      select,
      setSelect,
      data,
      setData,

      createCustomer,
      createLocal,
      suscriptions,
    };
  }, [
    viewModalCreateCustomer,
    setViewModalCreateCustomer,
    viewModalCreateLocal,
    setViewModalCreateLocal,
    viewModalEditLocal,
    setViewModalEditLocal,
    viewModalEditCustomer,
    setViewModalEditCustomer,
    viewCustomer,
    setViewCustomer,
    viewDelete,
    setViewDelete,
    customers,
    setCustomers,
    locals,
    setLocals,
    select,
    setSelect,
    data,
    setData,
  ]);
  return <CustomersContext.Provider value={value} {...props} />;
}

export function useCustomersContext() {
  const context = React.useContext(CustomersContext);
  if (!context) {
    throw new Error("No suscrito al contexto");
  }
  return context;
}

const getCustomersFunction = async ({ setCustomers }) => {
  try {
    const data = await Axios.get("/adminTool/customers");
    setCustomers(data.data);
    return true;
  } catch (e) {
    return false;
  }
};

const getLocalsFunction = async ({ setLocals, setLoading }) => {
  setLoading(true);
  try {
    const data = await Axios.get("/adminTool/locals", {
      params: {},
      headers: {
        authorization: window.localStorage.getItem("token")
      },
    });
    setLocals(data.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

const createCustomerFunction = async (data) => {
  try {
    await Axios.post("/adminTool/customers",
      {
        ...data
      },
      {
        headers: { authorization: window.localStorage.getItem("token") },
      }
    );
    return true;
  } catch (e) {
    return false;
  }
};

const createLocalFunction = async (data) => {
  try {
    await Axios.post("/adminTool/locals",
      {
        ...data
      },
      {
        headers: { authorization: window.localStorage.getItem("token") },
      }
    );
    return true;
  } catch (e) {
    return false;
  }
};

const getSuscriptionsFunction = async ({ setSuscriptions, setLoading }) => {
  setLoading(true);
  try {
    const data = await Axios.get("/adminTool/suscriptions", {
      params: {},
      headers: {
        authorization: window.localStorage.getItem("token")
      },
    });
    setSuscriptions(data.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

const deleteCustomerFunction = async ({ customerID }) => {
  try {
    await Axios.delete("/adminTool/customers", {
      params: { customerID },
      headers: {
        authorization: window.localStorage.getItem("token")
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};

const deleteLocalFunction = async ({ localID }) => {
  try {
    await Axios.delete("/adminTool/locals", {
      params: { localID },
      headers: {
        authorization: window.localStorage.getItem("token")
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};

const updateLocalFunction = async ( data ) => {
  try {
    await Axios.patch("/adminTool/locals", 
    {
      ...data,
    },
    {
      headers: { authorization: window.localStorage.getItem("token") },
    });
    return true;
  } catch (e) {
    return false;
  }
};

const updateCustomerFunction = async ( data ) => {
  try {
    await Axios.patch("/adminTool/customers", 
    {
      ...data,
    },
    {
      headers: { authorization: window.localStorage.getItem("token") },
    });
    return true;
  } catch (e) {
    return false;
  }
};