import React, { useState } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Import MomentUtils

function DateFilter() {
  // Set default start date to one month ago
  const defaultStartDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleDateChange();
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleDateChange();
  };

  const handleDateChange = () => {
    // Function to perform actions when date changes
    console.log('Date changed');
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          label="Fecha de inicio"
          value={startDate}
          onChange={handleStartDateChange}
          format="DD/MM/YYYY HH:mm" // Adjust format for Moment.js
        />
        <DateTimePicker
          label="Fecha de fin"
          value={endDate}
          onChange={handleEndDateChange}
          format="DD/MM/YYYY HH:mm" // Adjust format for Moment.js
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default DateFilter;
