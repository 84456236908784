import React from "react";
import {
  Card,
  CardContent,
  Paper,
  Grid,
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";
// import { useAppContext } from "../../../context/AppContext";
// import Axios from "axios";
import BarChartIcon from "@material-ui/icons/BarChart";
import MapIcon from "@material-ui/icons/Map";
import SyncIcon from "@material-ui/icons/Sync";
import SettingsIcon from "@material-ui/icons/Settings";
// import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import View from "./View";
import Table from "./Table";
import ModalCreate from "./ModalCreate";
import Locations from "./Locations";
import Configure from "./Configure";
import ChartsFilled from "./ChartsFilled";
import Header from "../../Navigation/Header";
import actionsSensors from "../../../redux/actions/sensors";
import { SensorsContextProvider } from "../../../context/SensorsContext";

const titles = {
  TABLE_SENSORS: "Sensores",
  FILL_SENSORS: "Gráfico de llenado de sensores",
  LOCATION: "Distribución geográfica de sensores",
  CONFIGURE: "Configurar sensores",
};

const styles = makeStyles(() => ({
  buttonGroup: {
    ['@media (max-width:320px)']: {
      fontSize: '0.5rem',
      display: 'flow-root',
      textTransform: 'none'
    }, ['@media (max-width:425px)']: {
      fontSize: '0.5rem',
      display: 'flow-root',
    },
    ['@media (max-width:780px)']: {
      fontSize: '0.5rem',
    }
  },
}))


function Index() {
  const [view, setView] = React.useState("TABLE_SENSORS");
  const [viewCreate, setViewCreate] = React.useState(false);
  // const [dataView, setDataView] = React.useState({});
  const [sensor, setSensor] = React.useState({});
  const classes = styles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actionsSensors.reset());
  }, [view]);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Header
            title={
              <Typography variant="h4">
                <SyncIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
                {titles[view]}
              </Typography>
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={12} align="end" >
              <hr />
              <ButtonGroup color="primary" size="small">
                <Button
                  variant={view === "TABLE_SENSORS" ? "contained" : "outlined"}
                  startIcon={<SyncIcon />}
                  onClick={() => setView("TABLE_SENSORS")}
                  className={classes.buttonGroup}
                >
                  Sensores
                    </Button>
                <Button
                  variant={view === "FILL_SENSORS" ? "contained" : "outlined"}
                  startIcon={<BarChartIcon />}
                  onClick={() => setView("FILL_SENSORS")}
                  className={classes.buttonGroup}
                >
                  Llenado
                    </Button>
                <Button
                  variant={view === "LOCATION" ? "contained" : "outlined"}
                  startIcon={<MapIcon />}
                  onClick={() => setView("LOCATION")}
                  className={classes.buttonGroup}
                >
                  Distribución
                    </Button>
                <Button
                  variant={view === "CONFIGURE" ? "contained" : "outlined"}
                  startIcon={<SettingsIcon />}
                  onClick={() => setView("CONFIGURE")}
                  className={classes.buttonGroup}
                >
                  Configuración
                    </Button>
                <Button
                  variant={view === "CREATE" ? "contained" : "outlined"}
                  onClick={() => setViewCreate(true)}
                  className={classes.buttonGroup}
                >
                  Registrar sensor
                    </Button>
              </ButtonGroup>
              <hr />
            </Grid>
            <Paper style={{ width: "100%", padding: 20 }}>
              {view === "VIEW_SENSOR" && (
                <View setView={setView} sensor={sensor} />
              )}
              {view === "LOCATION" && <Locations />}
              {view === "CONFIGURE" && <Configure />}
              {view === "FILL_SENSORS" && <ChartsFilled />}
              {view === "TABLE_SENSORS" && (
                <Table
                  setSensor={setSensor}
                  setView={setView}
                />
              )}
            </Paper>
          </Grid>
          <hr></hr>
          {viewCreate && <ModalCreate setViewCreate={setViewCreate} />}


        </CardContent>
      </Card>
    </Paper>
  );
}

export default function Sensors() {
  return (
    <SensorsContextProvider>
      <Index />
    </SensorsContextProvider>
  );
}
