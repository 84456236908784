import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
  ButtonGroup,
  makeStyles,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EcoIcon from "@material-ui/icons/Eco";
import MapIcon from "@material-ui/icons/Map";

import DispositionsList from "./DispositionsList";
import Locations from "./Locations";
import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import ModalDelete from "./ModalDelete";

import regProvComsData from "../../../resources/regProvCom";
import typeOfTreatments from "../../../resources/typeOfTreatments";
import dispositionsActions from "../../../redux/actions/dispositions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Index() {
  const classes = useStyles();

  const [view, setView] = useState("TABLE_DISPOSITIONS");
  const [createModal, setCreateModal] = useState(false);
  const [communesData] = useState([]);
  const [regionData] = useState([]);
  const [provinceData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const start = async () => {
      dispatch(dispositionsActions.fetchDispositions());
    };
    start();
  }, []);

  // REGIONES PROVINCIAS Y COMUNAS
  useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionData.push({
        regCode: regionsElements.region_iso_3166_2,
        regName: regionsElements.region,
      })
      regionsElements.provincias.forEach(provinceElement => {
        provinceData.push({
          provCode: provinceElement.name,
        })
        provinceElement.comunas.forEach(communeElement => {
          communesData.push({
            name: communeElement.name,
            code: communeElement.code,
          })
        })
      })
    })
  }, [])

  return (
    <Paper className={classes.root}>
      <ModalCreate createModal={createModal} setCreateModal={setCreateModal} typeOfTreatments={typeOfTreatments} />
      <ModalEdit typeOfTreatments={typeOfTreatments} />
      <ModalDelete />
      {/* <ModalView /> */}
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">Plantas de Disposición Final</Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setCreateModal(true)}
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
              >
                Agregar disposición final
              </Button>
            </Grid>
          </Grid>
          <hr></hr>
          <Grid container spacing={2}>
            <Grid item xs={12} align="end" >
              <ButtonGroup color="primary" size="small">
                <Button
                  variant={view === "TABLE_DISPOSITIONS" ? "contained" : "outlined"}
                  startIcon={<EcoIcon />}
                  onClick={() => setView("TABLE_DISPOSITIONS")}
                  className={classes.buttonGroup}
                >
                  Disposiciones
                </Button>
                <Button
                  variant={view === "LOCATION" ? "contained" : "outlined"}
                  startIcon={<MapIcon />}
                  onClick={() => setView("LOCATION")}
                  className={classes.buttonGroup}
                >
                  Distribución
                </Button>
              </ButtonGroup>
              <hr />
            </Grid>
            <Paper style={{ width: "100%", padding: 20 }}>
              {view === "LOCATION" && <Locations regionData={regionData} provinceData={provinceData} typeOfTreatments={typeOfTreatments} />}
              {view === "TABLE_DISPOSITIONS" && <DispositionsList communesData={communesData} typeOfTreatments={typeOfTreatments} />}
            </Paper>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default Index;
