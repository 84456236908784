import React, { useState, useEffect } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Card, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const token = process.env.REACT_APP_MAPGL_API_KEY;

export default function Map({ formData }) {
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: -0.232887,
    longitude: -78.478673,
    zoom: 14,
  });
  useEffect(() =>
    setViewport({
      ...viewport,
      latitude: parseFloat(formData.position.latitude)
        ? parseFloat(formData.position.latitude)
        : 0,
      longitude: parseFloat(formData.position.longitude)
        ? parseFloat(formData.position.longitude)
        : 0,
    }), [
    formData.position.longitude,
    formData.position.latitude
  ]);

  return (
    <ReactMapGL
      className="mb-map"
      mapboxApiAccessToken={token}
      {...viewport}
      width="100%"
      height={400}
      mapStyle={
          "mapbox://styles/mapbox/light-v10"
          // : "mapbox://styles/mapbox/dark-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={true}
    >
      <Marker
        key={0}
        longitude={formData.position.longitude}
        latitude={formData.position.latitude}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LocationOnIcon
              color="primary"
              style={{
                fontSize: 44,
              }}
            />
          </Grid>
          <Grid item align="center">
            <Card>
              <Typography style={{ margin: 10 }} variant="body1">
                {formData.name ? formData.name : <em>sucursal</em>}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Marker>
    </ReactMapGL>
  );
}

Map.propTypes = {
  formData: PropTypes.object.isRequired,
};
