import React, { useState } from "react";
import ReactMapGL, {
  Marker,
  Popup,
} from "react-map-gl";  // ONLY USE v5.2.11

import PropTypes from "prop-types";

import DispositionPin from "./DispositionPin";
import DispositionInfo from "./DispositionInfo";
import Symbology from "./Symbology";

const token = process.env.REACT_APP_MAPGL_API_KEY;

const legend = {
  position: "absolute",
  bottom: 18,
  right: 0,
  padding: "10px",
  zIndex:3,
};

export default function Map({ data }) {
  const [info, setInfo] = useState(null);

  const [viewport, setViewport] = useState({
    latitude: -33.418948,
    longitude: -70.577940,
    zoom: 14,
  });

  React.useEffect(() => {
    if (data.length) {
      var latX = 0;
      var longX = 0;
      data.forEach((element) => {
        latX += element.position.latitude;
        longX += element.position.longitude;
      });
      latX = latX / data.length;
      longX = longX / data.length;
    }
    setViewport((v) => {
      return {
        ...v,
        latitude: latX,
        longitude: longX,
      };
    });
  }, [data]);

  function RenderPopup() {
    return (
      info && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={info.position.longitude}
          latitude={info.position.latitude}
          closeOnClick={false}
          onClose={() => setInfo(null)}
        >
          <DispositionInfo info={info} />
        </Popup>
      )
    );
  }

  return (
    <ReactMapGL
      mapboxApiAccessToken={"pk.eyJ1Ijoiam9zZXBlZHJhemEiLCJhIjoiY2sxZWpjMGcyMGprZjNjbXZvaXowMHJibCJ9.T2vE902XE8a_WVB3--0Ssg"}
      {...viewport}
      width="100%"
      height="90vh"
      mapStyle={"mapbox://styles/mapbox/light-v10"}
      onViewportChange={(viewport) => setViewport(viewport)}
      style={{ border: "2px solid #003398" }}
    >
      <div style={legend}>
        <Symbology />
      </div>
      {data.map((disposition, index) => {
        return (
          <Marker
            key={`marker-${index}`}
            latitude={disposition.position.latitude}
            longitude={disposition.position.longitude}
          >
            <DispositionPin size={20} onClick={() => setInfo(disposition)} data={disposition} />
          </Marker>)
      })}
      {RenderPopup()}
    </ReactMapGL>
  );
}

Map.propTypes = {
  data: PropTypes.array.isRequired,
};
