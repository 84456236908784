import React, {useState} from "react";
import {Grid, MenuItem, TextField, InputAdornment} from "@material-ui/core";
import Map from "./Map";
import { useSensorsContext } from "../../../../context/SensorsContext";
import actionsSensors from "../../../../redux/actions/sensors";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {Autocomplete} from "@material-ui/lab";

export default function Locations() {
  const { getSensorsLocation } = useSensorsContext();
  const [locations, setLocations] = React.useState([]);
  const [typeSearch, setTypeSearch] = useState("SUCURSAL");
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const sensors = useSelector((state) => state.sensors);

  React.useEffect(() => {
    if (sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY"){
      dispatch(actionsSensors.fetchSensorsLocationByLocal({ localID: sensors.localID }));
    }
    if (sensors.customerID !== "EMPTY" && sensors.localID === "EMPTY"){
      dispatch(actionsSensors.fetchSensorsLocationBySede({ customerID: sensors.customerID }));
    }
    if(sensors.localID === "EMPTY" && sensors.customerID === "EMPTY")
      getSensorsLocation().then((response) => {
        setLocations(response);
      });

  }, [sensors.localID, sensors.customerID, getSensorsLocation]);

  const handleChangeLocal = (e) => {
    if(e === "" || e === 0 || e === null || e === undefined){
      //Control de excepciones
    }else{
      dispatch(actionsSensors.setLocalID({ localID: e._id }))
    }
  }
  const handleChangeSede = (e) => {
    if(e === "" || e === 0 || e === null || e === undefined){
      //Control de excepciones
    }else {
      dispatch(actionsSensors.setCustomerID({ customerID: e._id }))
    }
  }

  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
                id="outlined-required"
                label="Tipo de búsqueda"
                variant="outlined"
                margin="dense"
                type="text"
                value={typeSearch}
                onChange={(e) => setTypeSearch(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                  ),
                }}
                select
                fullWidth
            >
              <MenuItem value="EMPRESA">Empresa</MenuItem>
              <MenuItem value="SUCURSAL">Sucursales</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            {typeSearch === "SUCURSAL"  ? (
                <Autocomplete
                    id="cmbSucursal"
                    options={utils.locals}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option,value) => option._id === value._id}
                    onSelect={(event, newValue) => {
                      handleChangeLocal(newValue);
                    }}
                    onChange={(event, newValue) => {
                      handleChangeLocal(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   margin="dense"
                                   fullWidth
                                   label="Seleccione una sucursal"
                                   variant="outlined"
                        />
                    }
                />
            ):(<>
              <Autocomplete
                  id="cmbSede"
                  options={utils.customers}
                  freeSolo
                  getOptionLabel={(option) => option.brand}
                  getOptionSelected={(option,value) => option._id === value._id}
                  onSelect={(event, newValue) => {
                    handleChangeSede(newValue);
                  }}
                  onChange={(event, newValue) => {
                    handleChangeSede(newValue);
                  }}
                  renderInput={(params) =>
                      <TextField {...params}
                                 margin="dense"
                                 fullWidth
                                 label="Seleccione una sede"
                                 variant="outlined"
                      />
                  }
              />
            </>)}
          </Grid>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
        </Grid>
        <br />

    <Grid container spacing={2}>
      <Grid item xs={12}>
        {sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY" &&
          <Map data={sensors.location}/>
        }
        {sensors.customerID !== "EMPTY" && sensors.localID === "EMPTY" &&
            <Map data={sensors.location}/>
        }
        {sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" &&
        <Map data={locations}/>
        }
      </Grid>
    </Grid>
      </>
  );
}
