import React, {useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import {Button, ButtonGroup, Grid, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import Rssi from "../Rssi";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSensorsContext } from "../../../../context/SensorsContext";
import swal from 'sweetalert';
import actionsSensors from "../../../../redux/actions/sensors";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {Autocomplete} from "@material-ui/lab";
import LinearProgressBattery from "../LinearProgressBattery";
import LinearProgressFill from "../LinearProgressFill";
import Battery from "../Battery";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Modelo",
  },
  {
    id: "local",
    numeric: false,
    disablePadding: false,
    label: "Empresa / Local",
  },
  {
    id: "rssi",
    numeric: false,
    disablePadding: false,
    label: "Nivel",
  },
  {
    id: "IMEI",
    numeric: false,
    disablePadding: false,
    label: "IMEI",
  },
  {
    id: "battery",
    numeric: false,
    disablePadding: false,
    label: "Batería",
  },
  {
    id: "fill",
    numeric: false,
    disablePadding: false,
    label: "Llenado",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Última lectura",
  },
  { id: "options", numeric: false, disablePadding: false, label: "Opciones" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        ""
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({ setSensor, setView }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("datetimeCreated");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const { getSensorsTable, putSensorState} = useSensorsContext();
  let [rows, setRows] = React.useState([]);
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const sensors = useSelector((state) => state.sensors);
  const [typeSearch, setTypeSearch] = useState("SUCURSAL");
  const [sendRequest, setSendRequest] = React.useState({sensorID:0,isClick:false})


  React.useEffect(() => {
    if (sendRequest.isClick) {
      dispatch(actionsSensors.putSensorState({sensorID:sendRequest.sensorID}))
      setSendRequest({isClick: false})
    }
    if (sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY"){
      dispatch(actionsSensors.sensorsTableByLocal({ localID: sensors.localID }));
    }
    if (sensors.customerID !== "EMPTY" && sensors.localID === "EMPTY" && sensors.model === "EMPTY"){
      dispatch(actionsSensors.sensorsTableBySede({ customerID: sensors.customerID }));
    }
    if (sensors.customerID === "EMPTY" && sensors.localID === "EMPTY" && sensors.model !== "EMPTY"){
      dispatch(actionsSensors.sensorsTableByModel({ model: sensors.model }));
    }
    if(sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY")
      getSensorsTable().then((response) => {
        setRows(response);
      });

  }, [sensors.localID,getSensorsTable,sensors.customerID,sensors.model,sendRequest.isClick]);
  const viewSensorFunction = async (data) => {
    await setSensor(data);
    await setView("VIEW_SENSOR");
  };
  const model = [{ name :"CIRC10"}, {name: "CIRC20"}];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  //Creacion de un diferencial entre la fecha actual y la fecha de el ultimo updatedAt
  const difference = (date) => {
    const now = new Date();
    const current = new Date(date);
    const diffTime = Math.abs(now - current);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffDays > 1) {
      return { status: false, hours: diffHours - 1 , days : diffDays - 1};
    } else {
      return { status: true, hours: diffHours - 1 ,days : diffDays - 1};
    }
  };
  const handleChangeLocal = (e) => {
    if(e === "" || e === 0 || e === null || e === undefined){
      //Control de excepciones
    }else{
      dispatch(actionsSensors.setLocalID({ localID: e._id }))
    }
  }
  const handleChangeSede = (e) => {
    if(e === "" || e === 0 || e === null || e === undefined){
      //Control de excepciones
	  console.log(e);
    }else {
		 console.log("hola" + e._id);
      dispatch(actionsSensors.setCustomerID({ customerID: e._id }))
    }
  }
  const handleChangeModel = (e) => {
    if(e === "" || e === 0 || e === null || e === undefined){
      //Control de excepciones
    }else {
      dispatch(actionsSensors.setModel({ model: e.name }))
    }
  }


  return (
      <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
              id="outlined-required"
              label="Tipo de búsqueda"
              variant="outlined"
              margin="dense"
              type="text"
              value={typeSearch}
              onChange={(e) => setTypeSearch(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                ),
              }}
              select
              fullWidth
          >
            <MenuItem value="EMPRESA">Empresa</MenuItem>
            <MenuItem value="SUCURSAL">Sucursales</MenuItem>
            <MenuItem value="MODELO">Modelo</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          {typeSearch === "SUCURSAL"  ? (
              <Autocomplete
                  id="cmbSucursal"
                  options={utils.locals}
                  freeSolo
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option,value) => option._id === value._id}
                  onSelect={(event, newValue) => {
                    handleChangeLocal(newValue);
                  }}
                  onChange={(event, newValue) => {
                    handleChangeLocal(newValue);
                  }}
                  renderInput={(params) =>
                      <TextField {...params}
                                 margin="dense"
                                 fullWidth
                                 label="Seleccione una sucursal"
                                 variant="outlined"
                      />
                  }
              />
          ):typeSearch === "EMPRESA"  ? (
              <Autocomplete
                  id="cmbSede"
                  options={utils.customers}
                  freeSolo
                  getOptionLabel={(option) => option.brand}
                  getOptionSelected={(option,value) => option._id === value._id}
                  onSelect={(event, newValue) => {
                    handleChangeLocal(newValue);
                  }}
                  onChange={(event, newValue) => {
                    handleChangeSede(newValue);
                  }}
                  renderInput={(params) =>
                      <TextField {...params}
                                 margin="dense"
                                 fullWidth
                                 label="Seleccione una sede"
                                 variant="outlined"
                      />
                  }
              />
          ): typeSearch === "ESTADOLLENADO"  ? (
              <Autocomplete
                  id="cmbEstadoLlenado"
                  options={utils.locals}
                  freeSolo
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option,value) => option._id === value._id}
                  onSelect={(event, newValue) => {
                    handleChangeLocal(newValue);
                  }}
                  onChange={(event, newValue) => {
                    handleChangeLocal(newValue);
                  }}
                  renderInput={(params) =>
                      <TextField {...params}
                                 margin="dense"
                                 fullWidth
                                 label="Seleccione un estado de llenado"
                                 variant="outlined"
                      />
                  }
              />
          ): (<>
            <Autocomplete
                id="cmbModelo"
                options={model}
                freeSolo
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option,value) => option._id === value._id}
                onSelect={(event, newValue) => {
                  handleChangeModel(newValue);
                }}
                onChange={(event, newValue) => {
                  handleChangeModel(newValue);
                }}
                renderInput={(params) =>
                    <TextField {...params}
                               margin="dense"
                               fullWidth
                               label="Seleccione un modelo"
                               variant="outlined"
                    />
                }
            />
          </>)}
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
      </Grid>
      <br />
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY" && (
            <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let diff = difference(row.lastReadDate);
                  const isItemSelected = isSelected(index);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <StyledTableCell align="left">
                        {row.model}
                      </StyledTableCell>
                       <StyledTableCell align="left">
                        {row.customers.brand+" / "+row.localID.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/*Creación de rssi*/}
                        {row.rssi >=0 && row.lastReadDate? (
                            <Rssi rssi={row.rssi} />
                        ):(
                            <em>No se registra</em>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.imei}</StyledTableCell>
                      <StyledTableCell align="left">{row.battery >=0 && row.lastReadDate? (
                          <Battery battery={row.battery} />
                      ):(
                          <em>No se registra</em>
                      )}</StyledTableCell>
                      <StyledTableCell align="left">{row.fill >=0 && row.lastReadDate? (
                          <LinearProgressFill value={row.fill} />
                      ):(
                          <em>No se registra</em>
                      )}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.lastReadDate ? (
                            <Tooltip title={"Hace " + diff.hours + " horas, "+ diff.days + " días"}>
                              <div>
                                {diff.status ? (
                                    <SyncIcon style={{ color: "#00C851" }} />
                                ) : (
                                    <SyncProblemIcon style={{ color: "#ffbb33" }} />
                                )}
                                {new Intl.DateTimeFormat("es-CL", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }).format(new Date(row.lastReadDate))}
                              </div>
                            </Tooltip>
                        ) : (
                            <em>No se registra</em>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <ButtonGroup size="small" variant="outlined">
                          <Button
                              color="primary"
                              onClick={() => viewSensorFunction(row)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                              color="primary"
                              onClick={()=>swal({
                                title: "Deshabilitar sensor",
                                text: "¿Está seguro que desea eliminar este sensor?",
                                icon: "warning",
                                buttons: {send: {value: "funciona"}},
                                dangerMode: true,
                              })
                                  .then((value) => {
                                    if (value) {

                                      setSendRequest({sensorID : row._id, isClick:true});
                                      swal("Sensor deshabilitado", {
                                        icon: "success",
                                      });
                                    }
                                  })}
                          >
                            <DeleteIcon />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        )}
        {sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY" && (
            <TableContainer>
          <Table
              className={classes.table}
              size="small"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
          >
            <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={sensors.table.length}
            />
            <TableBody>
              {stableSort(sensors.table, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    let diff = difference(row.lastReadDate);
                    const isItemSelected = isSelected(index);
                    return (
                        <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            {row.model}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.customers.brand+" / "+row.localID.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {/*Creación de rssi*/}
                            {row.rssi >=0 ? (
                                <Rssi rssi={row.rssi} />
                            ):(
                                <em>No se registra</em>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.imei}</StyledTableCell>
                          <StyledTableCell align="left">{row.battery >=0 ? (
                              <Battery battery={row.battery} />
                          ):(
                              <em>No se registra</em>
                          )}</StyledTableCell>
                          <StyledTableCell align="left">{row.fill >=0 ? (
                              <LinearProgressFill value={row.fill} />
                          ):(
                              <em>No se registra</em>
                          )}</StyledTableCell>
                          <StyledTableCell align="left">
                            {row.lastReadDate ? (
                                <Tooltip title={"Hace " + diff.hours + " horas, "+ diff.days + " días"}>
                                  <div>
                                    {diff.status ? (
                                        <SyncIcon style={{ color: "#00C851" }} />
                                    ) : (
                                        <SyncProblemIcon style={{ color: "#ffbb33" }} />
                                    )}
                                    {new Intl.DateTimeFormat("es-CL", {
                                      year: "numeric",
                                      month: "long",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }).format(new Date(row.lastReadDate))}
                                  </div>
                                </Tooltip>
                            ) : (
                                <em>No se registra</em>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <ButtonGroup size="small" variant="outlined">
                              <Button
                                  color="primary"
                                  onClick={() => viewSensorFunction(row)}
                              >
                                <VisibilityIcon />
                              </Button>
                              <Button
                                  color="primary"
                                  onClick={()=>swal({
                                    title: "Deshabilitar sensor",
                                    text: "¿Está seguro que desea eliminar este sensor?",
                                    icon: "warning",
                                    buttons: {send: {value: "funciona"}},
                                    dangerMode: true,
                                  })
                                      .then((value) => {
                                        if (value) {
                                          setSendRequest({sensorID : row._id, isClick:true});
                                          swal("Sensor deshabilitado", {
                                            icon: "success",
                                          });
                                        }
                                      })}
                              >
                                <DeleteIcon />
                              </Button>
                            </ButtonGroup>
                          </StyledTableCell>
                        </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        )}
        {sensors.localID === "EMPTY" && sensors.customerID !== "EMPTY" && sensors.model === "EMPTY" && (
            <TableContainer>
              <Table
                  className={classes.table}
                  size="small"
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
              >
                <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={sensors.table.length}
                />
                <TableBody>
                  {stableSort(sensors.table, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        let diff = difference(row.lastReadDate);
                        const isItemSelected = isSelected(index);
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                            >
                              <StyledTableCell align="left">
                                {row.model}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.customers.brand+" / "+row.localID.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {/*Creación de rssi*/}
                                {row.rssi>=0 ? (
                                    <Rssi rssi={row.rssi} />
                                ):(
                                    <em>No se registra</em>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.imei}</StyledTableCell>
                              <StyledTableCell align="left">{row.battery>=0 ? (
                                  <Battery battery={row.battery} />
                              ):(
                                  <em>No se registra</em>
                              )}</StyledTableCell>
                              <StyledTableCell align="left">{row.fill >=0? (
                                  <LinearProgressFill value={row.fill} />
                              ):(
                                  <em>No se registra</em>
                              )}</StyledTableCell>
                              <StyledTableCell align="left">
                                {row.lastReadDate ? (
                                    <Tooltip title={"Hace " + diff.hours + " horas, "+ diff.days + " días"}>
                                      <div>
                                        {diff.status ? (
                                            <SyncIcon style={{ color: "#00C851" }} />
                                        ) : (
                                            <SyncProblemIcon style={{ color: "#ffbb33" }} />
                                        )}
                                        {new Intl.DateTimeFormat("es-CL", {
                                          year: "numeric",
                                          month: "long",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }).format(new Date(row.lastReadDate))}
                                      </div>
                                    </Tooltip>
                                ) : (
                                    <em>No se registra</em>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <ButtonGroup size="small" variant="outlined">
                                  <Button
                                      color="primary"
                                      onClick={() => viewSensorFunction(row)}
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                  <Button
                                      color="primary"
                                      onClick={()=>swal({
                                        title: "Deshabilitar sensor",
                                        text: "¿Está seguro que desea eliminar este sensor?",
                                        icon: "warning",
                                        buttons: {send: {value: "funciona"}},
                                        dangerMode: true,
                                      })
                                          .then((value) => {
                                            if (value) {
                                              setSendRequest({sensorID : row._id, isClick:true});
                                              swal("Sensor deshabilitado", {
                                                icon: "success",
                                              });
                                            }
                                          })}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </ButtonGroup>
                              </StyledTableCell>
                            </TableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        )}
        {sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" && sensors.model !== "EMPTY" && (
            <TableContainer>
              <Table
                  className={classes.table}
                  size="small"
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
              >
                <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={sensors.table.length}
                />
                <TableBody>
                  {stableSort(sensors.table, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        let diff = difference(row.lastReadDate);
                        const isItemSelected = isSelected(index);
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                            >
                              <StyledTableCell align="left">
                                {row.model}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.customers.brand+" / "+row.localID.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {/*Creación de rssi*/}
                                {row.rssi ? (
                                    <Rssi rssi={row.rssi} />
                                ):(
                                    <em>No se registra</em>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.imei}</StyledTableCell>
                              <StyledTableCell align="left">
                                {row.lastReadDate ? (
                                    <Tooltip title={"Hace " + diff.hours + " horas, "+ diff.days + " días"}>
                                      <div>
                                        {diff.status ? (
                                            <SyncIcon style={{ color: "#00C851" }} />
                                        ) : (
                                            <SyncProblemIcon style={{ color: "#ffbb33" }} />
                                        )}
                                        {new Intl.DateTimeFormat("es-CL", {
                                          year: "numeric",
                                          month: "long",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }).format(new Date(row.lastReadDate))}
                                      </div>
                                    </Tooltip>
                                ) : (
                                    <em>No se registra</em>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <ButtonGroup size="small" variant="outlined">
                                  <Button
                                      color="primary"
                                      onClick={() => viewSensorFunction(row)}
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                  <Button
                                      color="primary"
                                      onClick={()=>swal({
                                        title: "Deshabilitar sensor",
                                        text: "¿Está seguro que desea eliminar este sensor?",
                                        icon: "warning",
                                        buttons: {send: {value: "funciona"}},
                                        dangerMode: true,
                                      })
                                          .then((value) => {
                                            if (value) {
                                              putSensorState({sensorID : row._id});
                                              swal("Sensor deshabilitado", {
                                                icon: "success",
                                              });
                                            }
                                          })}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </ButtonGroup>
                              </StyledTableCell>
                            </TableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        )}
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
      </>
  );
}

EnhancedTable.propTypes = {
  setView: PropTypes.func.isRequired,
  setSensor: PropTypes.func.isRequired,
};