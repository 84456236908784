import React from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Card, Typography } from "@material-ui/core";
import { useAppContext } from "../../../../context/AppContext";

function Map({ lat, long, local }) {
  const { themeColor } = useAppContext();
  const [viewport, setViewport] = React.useState({
    width: "100%",
    height: 400,
    latitude: 0,
    longitude: 0,
    zoom: 13,
  });

  React.useEffect(() => {
    setViewport((v) => {
      return {
        ...v,
        latitude: parseFloat(lat) ? parseFloat(lat) : 0,
        longitude: parseFloat(long) ? parseFloat(long) : 0,
      };
    });
  }, [lat, long]);

  return (
    <ReactMapGL
      mapboxApiAccessToken={
        "pk.eyJ1Ijoiam9zZXBlZHJhemEiLCJhIjoiY2sxZWpjMGcyMGprZjNjbXZvaXowMHJibCJ9.T2vE902XE8a_WVB3--0Ssg"
      }
      {...viewport}
      mapStyle={
        // themeColor === "dark"
        //   ? "mapbox://styles/mapbox/dark-v10"
        //   :
           "mapbox://styles/mapbox/light-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={false}
    >
      <Marker key={0} longitude={long} latitude={lat}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LocationOnIcon
              color="primary"
              style={{
                fontSize: 44,
              }}
            />
          </Grid>
          <Grid item align="center">
            <Card>
              <Typography style={{ margin: 10 }} variant="body1">
                {local}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Marker>
    </ReactMapGL>
  );
}

export default Map;
