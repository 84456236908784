import axios from "axios";

const fetchData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/removals/getDataCreateRemoval");
      const customers = await axios.get("/adminTool/customers");
      const dispositions = await axios.get("/adminTool/dispositions");
      const transporterlist = await axios.get("/adminTool/transporterList");
      console.log(response);
      dispatch({
        type: "UTILS_FETCH_LOCALS",
        payload: response.data.locals
      });
      dispatch({
        type: "UTILS_FETCH_TRANSPORTERS",
        payload: response.data.transporters,
      });
      dispatch({
        type: "UTILS_FETCH_CUSTOMERS",
        payload: customers.data,
      });
      dispatch({
        type: "UTILS_FETCH_DISPOSITIONS",
        payload: dispositions.data,
      });
      dispatch({
        type: "UTILS_FETCH_TRANSPORTERLIST",
        payload: transporterlist.data,
      });
      
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  fetchData,
};
