import React from "react";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { ClockLoader } from "react-spinners";

const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    width: "100%",
    maxWidth: 500,
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    [breakpoints.up("sm")]: {
      textAlign: "start",
      flexDirection: "row",
    },
  },
  overline: {
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.7rem",
    opacity: 0.7,
  },
  heading: {
    fontWeight: "450",
    color: "#ffffff",
    letterSpacing: 0.5,
  },
}));

export default function RewardCard({ value, image, background, title }) {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);

  return (
    <Card
      className={styles.card}
      style={{ opacity: hover ? 0.7 : 1, background }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardContent className={styles.content} style={{ padding: 0 }}>
        <Typography className={styles.overline} variant={"overline"}>
          {title}
        </Typography>
        <Typography className={styles.heading} variant={"h4"} gutterBottom>
          {value === "..." ? (
            <ClockLoader color="#fff" size={36} loading={true} />
          ) : (
            value
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}
