import React from "react";
import {
  Grid,
  Card,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useAppContext } from "../../../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
}));


export default function LocalInfo({ info }) {
  const classes = useStyles();
  
  function Materials(materialID, i) {
    const { helpers } = useAppContext();
  
    return (
      <Grid item align="center" key={"pointer_truck_" + i} >
        <Card style={{ padding: 16, minWidth: 200, zIndex: 2 }}>
          <div>
            <Typography
              variant="body2"
              color="textSecondary"
              align="left"
            >
              {helpers[materialID.toLowerCase()].name}
            </Typography>
          </div>
        </Card>
      </Grid>
    )
  }

  return (
    <Grid>
      <Grid>
        <Typography className={classes.title}>
          {info.name}
        </Typography>
        <Typography className={classes.subtitles}>
          {info.address}
        </Typography>
        <Typography className={classes.subtitles}>
          {info.email}
        </Typography>
        <hr></hr>
        <Typography >
          Materiales que recibe:
        </Typography>
        {info.materialsID && info.materialsID.map((materialID, i) => {
          return Materials(materialID, i)
        })}
      </Grid>
    </Grid>
  );
}