import Axios from "axios";

const setLocalID = ({ localID }) => {
    return async (dispatch) => {
        dispatch({ type: "SENSORS_SET_LOCALID", payload: { localID } });
    };
};
const setSensors = ({ configuration }) => {
    return async (dispatch) => {
        dispatch({ type: "SENSORS_SET_MATERIAL", payload: { configuration } });
    };
};
const reset = () => {
    return async (dispatch) => {
        dispatch({ type: "RESET" });
    };
};
const setCustomerID = ({ customerID }) => {
	console.log("entro");
    return async (dispatch) => {
        dispatch({ type: "SENSORS_SET_CUSTOMERID", payload: { customerID } });
    };
};
const setModel = ({ model }) => {
    return async (dispatch) => {
        dispatch({ type: "SENSORS_SET_MODEL", payload: { model } });
    };
};

const fetchSensors = () => {
    return async (dispatch, getState) => {
        try {
            let response = await Axios.get("/adminTool/sensors/locationByID", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { localID: getState().sensors.localID },
            });
            dispatch({ type: "SENSORS_FETCH", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const fetchSensorsFilled = () => {
    return async (dispatch, getState) => {
        try {
            let response = await Axios.get("/adminTool/sensors/filledByID", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { localID: getState().sensors.localID },
            });
            dispatch({ type: "SENSORS_FETCH_FILLED", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const fetchSensorsFilledBySede = ({ customerID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/filledByIDCustomer", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { customerID: customerID },
            });
            dispatch({ type: "SENSORS_FETCH_FILLED_SEDE", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const sensorsTableByLocal = ({ localID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsTableByLocal", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { localID: localID },
            });
            dispatch({ type: "SENSORS_TABLE_BY_LOCAL", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const sensorsConfigureByLocal = ({ localID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsTableByLocal", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { localID: localID },

            });

            dispatch({ type: "SENSORS_CONFIGURATION_BY_LOCAL", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const fetchSensorsLocationByLocal = ({ localID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsLocationByLocal", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { localID: localID },
            });
            dispatch({ type: "SENSORS_LOCATION_BY_LOCAL", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const fetchSensorsLocationBySede = ({ customerID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsLocationBySede", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { customerID: customerID },
            });
            dispatch({ type: "SENSORS_LOCATION_BY_SEDE", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const putSensorState = ({ sensorID }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/stateSensorByID", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { _id: sensorID, }
            });
            dispatch({ type: "SENSORSTATE_REFUSE", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};


const sensorsTableBySede = ({ customerID }) => {
    return async (dispatch) => {
		console.log("entro3");
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsTableBySede", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { customerID: customerID },
            });
            dispatch({ type: "SENSORS_TABLE_BY_SEDE", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const sensorsTableByFill = ({ fill }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsTableByFill", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { fill: fill },
            });
            dispatch({ type: "SENSORS_TABLE_BY_FILL", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

const sensorsTableByModel = ({ model }) => {
    return async (dispatch) => {
        try {
            let response = await Axios.get("/adminTool/sensors/sensorsTableByModel", {
                headers: {
                    authorization: window.localStorage.getItem("token"),
                },
                params: { model: model },
            });
            dispatch({ type: "SENSORS_TABLE_BY_MODEL", payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
};

export default {

    fetchSensors,
    fetchSensorsFilled,
    fetchSensorsLocationBySede,
    fetchSensorsLocationByLocal,
    setLocalID,
    fetchSensorsFilledBySede,
    setCustomerID,
    sensorsTableByLocal,
    sensorsTableBySede,
    sensorsTableByFill,
    sensorsTableByModel,
    setModel,
    reset,
    setSensors,
    putSensorState,
    sensorsConfigureByLocal,
};
