export const residuesTypes = [
  {name: 'METALS', title: 'Otros Metales'},
  {name: 'TETRAPAK', title: 'Tetra pak'},
  //{name: 'ORGANICS', title: 'Orgánicos'},
  {name: 'ALUMINIUM', title: 'Aluminio'},
  {name: 'ELECTRONICS', title: 'Electrónicos'},
  //{name: 'PLASTIC', title: 'Plástico'},
  {name: 'TEXTILS', title: 'Textil'},
  {name: 'GLASS', title: 'Vidrio'},
  //{name: 'CEL', title: 'Celulosa'},
  {name: 'WOOD', title: 'Madera'},
  {name: 'PAPER', title: 'Papel'},
  {name: 'PAPERBOARD', title: 'Cartón'},
  {name: 'PET1', title: 'Pet1'},
  {name: 'FILM', title: 'Film'},
  {name: 'OIL', title: 'Aceite orgánico'},
  {name: 'OTHERS', title: 'Otros'},
];

export const parseFlag = (flag) => {
  const residue = residuesTypes.find(residue => residue.name === flag)
  return residue ? residue.title : `Material ${flag} invalido`;
}