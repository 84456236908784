import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { format } from "rut.js";

export default function ModalCreate({ formData, setFormData }) {
  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            name="brand"
            value={formData.brand}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Nombre empresa"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            name="businessName"
            value={formData.businessName}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Razón social"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            variant="outlined"
            name="rut"
            value={format(formData.rut)}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Rut"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            margin="dense"
            variant="outlined"
            name="businessActivity"
            value={formData.businessActivity}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Giro"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            name="addressSociety"
            value={formData.addressSociety}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Domicilio de la sociedad"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            name="legalRepresentName"
            value={formData.legalRepresentName}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Nombre representante legal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            name="legalRepresentRut"
            value={format(formData.legalRepresentRut)}
            onChange={(e) => updateData(e)}
            fullWidth
            label="Rut representante legal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
