import axios from "axios";
import notifiesActions from "./notifies";

const fetchRemovals = (page) => {
  return async (dispatch, getState) => {
    try {
      const removals = await getState().removals;
      const response = await axios.get("/adminTool/removals", {
        params: { select: removals.selector, search: removals.search, page: page+1 },
      });
      dispatch(fetchCards());
      dispatch({ type: "REMOVALS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return e;
    }
  };
};

const removalsCount = () => {
  return async (dispatch, getState) => {
    try {
      const removals = await getState().removals;
      const response = await axios.get("/adminTool/removalsCount");
      dispatch({ type: "REMOVALS_COUNT", payload: response.data });
      return true;
    } catch (e) {
      return e;
    }
  };
};

const valiRemovals = (data) => {
  data.statusTransport="COMPLETED";
  return async (dispatch) => {
    try {
      await axios.patch("/adminTool/removals", data);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_VALIDATE_SUCCESS"));
      dispatch(fetchRemovals());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_VALIDATE_FAIL"));
      return false;
    }
  };
};

const patchRemovals = (data) => {
  return async (dispatch) => {
	  
    try {
      await axios.patch("/adminTool/removals", data);
	  if(data.tipo==1){
		  dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_SUCCESS"));
	  }else{
		 dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_SUCCESS")); 
	  }
      
      dispatch(fetchRemovals());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
      return false;
    }
  };
};

const deleteRemoval = ({ removalID }) => {
  return async (dispatch) => {
    try {
      await axios.delete("/adminTool/removals", { params: { removalID } });
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_SUCCESS"));
      dispatch(fetchRemovals());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_FAIL"));
      return false;
    }
  };
};

const fetchCards = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/removals/cards");
      dispatch({ type: "REMOVALS_SET_CARDS", payload: response.data });
      return true;
    } catch (e) {
      return e;
    }
  };
};

const setSelector = (selector) => {
  return async (dispatch) => {
    await dispatch({ type: "REMOVALS_SET_SELECTOR", payload: selector });
    await dispatch(fetchRemovals());
    return true;
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_CREATE_MODAL", payload: value });
};

const setViewExtraModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_VIEW_EXTRA_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_DELETE_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_DATA_MODAL", payload: value });
};

const setDataExtraModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_DATA_EXTRA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "REMOVALS_SET_SEARCH", payload: value });
    await dispatch(fetchRemovals());
  };
};

const uploadExcel = (formData) => {
  return async (dispatch) => {
    try {
      //debugger;
      const response = await axios.post("/adminTool/removals/excel", formData, 
      {headers: { authorization: window.localStorage.getItem("token"), 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (e) {
      return false;
    }
  };
};

export default {
  fetchRemovals,
  removalsCount,
  patchRemovals,
  valiRemovals,
  deleteRemoval,
  fetchCards,
  setSelector,
  setCreateModal,
  setViewExtraModal,
  setDeleteModal,
  setDataModal,
  setDataExtraModal,
  setSearch,
  uploadExcel
};
