import React, {useState} from "react";
import Container from "./Container";
import { useSensorsContext } from "../../../../context/SensorsContext";
import {Grid, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import actionsSensors from "../../../../redux/actions/sensors";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {Autocomplete} from "@material-ui/lab";

export default function Index() {
  const { getSensorsFilled } = useSensorsContext();
  let [data, setData] = React.useState([]);
  const [typeSearch, setTypeSearch] = useState("SUCURSAL");
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const sensors = useSelector((state) => state.sensors);
  React.useEffect(() => {
    if (sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY"){
      dispatch(actionsSensors.fetchSensorsFilled({ localID: sensors.localID }));
    }
    if (sensors.customerID !== "EMPTY" && sensors.localID === "EMPTY" && sensors.model === "EMPTY"){
       dispatch(actionsSensors.fetchSensorsFilledBySede({ customerID: sensors.customerID }));
    }
    if(sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY")
    getSensorsFilled().then((response) => {
      setData(response);
    });

  }, [sensors.localID, sensors.customerID, getSensorsFilled, sensors.model]);

    const handleChangeLocal = (e) => {
      if(e === "" || e === 0 || e === null || e === undefined){
          //Control de excepciones
      }else{
          dispatch(actionsSensors.setLocalID({ localID: e._id }))
      }
    }
    const handleChangeSede = (e) => {
        if(e === "" || e === 0 || e === null || e === undefined){
            //Control de excepciones
        }else {
            dispatch(actionsSensors.setCustomerID({ customerID: e._id }))
        }
    }
  return (
      <>
      <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
                id="outlined-required"
                label="Tipo de búsqueda"
                variant="outlined"
                margin="dense"
                type="text"
                value={typeSearch}
                onChange={(e) => setTypeSearch(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                      <InputAdornment >
                        <SearchIcon />
                      </InputAdornment>
                  ),
                }}
                select
                fullWidth
            >
              <MenuItem value="EMPRESA">Empresa</MenuItem>
              <MenuItem value="SUCURSAL">Sucursales</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            {typeSearch === "SUCURSAL"  ? (
                <Autocomplete
                    id="cmbSucursal"
                    options={utils.locals}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option,value) => option._id === value._id}
                    onSelect={(event, newValue) => {
                        handleChangeLocal(newValue);
                    }}
                    onChange={(event, newValue) => {
                        handleChangeLocal(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   margin="dense"
                                   fullWidth
                                   label="Seleccione una sucursal"
                                   variant="outlined"
                        />
                    }
                />
            ):(<>
                <Autocomplete
                    id="cmbSede"
                    options={utils.customers}
                    freeSolo
                    getOptionLabel={(option) => option.brand}
                    getOptionSelected={(option,value) => option._id === value._id}
                    onSelect={(event, newValue) => {
                        handleChangeSede(newValue);
                    }}
                    onChange={(event, newValue) => {
                        handleChangeSede(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   margin="dense"
                                   fullWidth
                                   label="Seleccione una sede"
                                   variant="outlined"
                        />
                    }
                />
                </>)}
          </Grid>
      </Grid>
    <br />
    <div>
      {sensors.localID !== "EMPTY" && sensors.model === "EMPTY" && (sensors.filled.map((filled, i) => (
        <Container
          filled={filled.data}
          local={filled.local}
          key={"container_" + i}
        />
      )))}
        {sensors.customerID !== "EMPTY" && sensors.model === "EMPTY"  && (sensors.filled.map((filled, i) => (
            <Container
                filled={filled.data}
                local={filled.local}
                key={"container_" + i}
            />
        )))}
      {sensors.localID === "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY"  && (data.map((filled, i) => (
          <Container
              filled={filled.data}
              local={filled.local}
              key={"container_" + i}
          />
      )))}
    </div>
        </>
  );
}
