const enqueueNotify = (type) => {
  return (dispatch) => {
    switch (type) {
      case "REMOVAL_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el retiro",
            severity: "error",
          },
        });
        return true;
      }
	  case "REMOVAL_LEARNING_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Aprendizaje creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
	  case "REMOVAL_VALIDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro validado con éxito",
            severity: "success",
          },
        });
        return true;
      }
	  case "REMOVAL_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro actualizado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REPORT_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el retiro",
            severity: "error",
          },
        });
        return true;
      }
	  case "REMOVAL_VALIDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo validar el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "USER_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Usuario eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "USER_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el usuario",
            severity: "error",
          },
        });
        return true;
      }
      case "USER_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Usuario creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "USER_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el usuario",
            severity: "error",
          },
        });
        return true;
      }
      case "REPORT_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REPORT_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el reporte",
            severity: "error",
          },
        });
        return true;
      }
      case "TRANSPORTER_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Transportista creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "TRANSPORTER_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el/la transportista",
            severity: "error",
          },
        });
        return true;
      }
      case "TRANSPORTER_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Transportista eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "TRANSPORTER_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el/la transportista",
            severity: "error",
          },
        });
        return true;
      }
      case "TRANSPORTER_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Transportista actualizado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "TRANSPORTER_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo actualizar el/la transportista",
            severity: "error",
          },
        });
        return true;
      }
      case "DISPOSITION_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Disposición creada exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "DISPOSITION_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear la disposición final",
            severity: "error",
          },
        });
        return true;
      }
      case "DISPOSITION_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Disposición actualizada exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "DISPOSITION_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo actualizar la disposición final",
            severity: "error",
          },
        });
        return true;
      }
      case "SENSOR_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Cambios guardados con éxito",
            severity: "success",
          },
        });
        return true;
      }
      default: {
        return false;
      }
    }
  };
};

const dequeueNotify = () => {
  return (dispatch) => dispatch({ type: "NOTIFY_DEQUEUE" });
};

export default { enqueueNotify, dequeueNotify };
