import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import validator from "email-validator";

import regProvComsData from "../../../../resources/regProvCom";
import transportersActions from "../../../../redux/actions/transporters";

import General from "./General";
import Vehicles from "./Vehicles";
import { format } from "rut.js";

const initState = {
  company: "",
  rutEnterprise: "",
  address: "",
  commune: "13114",
  region: "CL-RM",
  province: "Santiago",
  name: "",
  lastName: "",
  rut: "",
  email: "",
  phone: "",
  certification: false,
  certificationURL: "",
  // categories: [],
  // days: [],
  // timeWindows: [],
  // communes: [],
};

const initRegProvCom = {
  name: "Las Condes",
  code: "13114",
  regCode: "CL-RM",
  provCode: "Santiago",
}

const CreateModal = ({ setOpen }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [communes] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [formData, setFormData] = useState(initState);
  const [communeSelected, setCommuneSelected] = useState(initRegProvCom);
  const [resolutionsSelected, setResolutionsSelected] = useState([]);
  const [fileValue, setFileValue] = useState("");
  const [pdfError, setPdfError] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  // REGIONES PROVINCIAS Y COMUNAS
  useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionsElements.provincias.forEach(provinceElement => {
        provinceElement.comunas.forEach(communeElement => {
          communes.push({
            name: communeElement.name,
            code: communeElement.code,
            regCode: regionsElements.region_iso_3166_2,
            provCode: provinceElement.name,
          })
        })
      })
    })
    communes.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    })
  }, [])

  const updateData = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const handleCloseModal = () => {
    resetFormData();
    setOpen(false);
  };

  const resetFormData = () => setFormData({ initState });

  const handleSend = () => {
	  formData.rutEnterprise=format(formData.rutEnterprise);
	  formData.rut=format(formData.rut);
    if (
      formData.name &&
      formData.lastName &&
      formData.rut &&
      formData.phone &&
      formData.address &&
      formData.commune &&
      formData.company &&
      formData.rutEnterprise &&
      validator.validate(formData.email)
    ) {
      dispatch(transportersActions.postTransporter({ transporter: formData, vehicles: vehiclesData, file: formData.file }));
      handleCloseModal();
    } else {
      setError(true);
    }
  };


  return (
    <Dialog open={true} onClose={handleCloseModal} scroll="body" maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>
              Agregar nuevo transportista
            </h4>
          </Grid>
          <hr></hr>
          <General
            formData={formData}
            setFormData={setFormData}
            updateData={updateData}
            communes={communes}
            communeSelected={communeSelected}
            setCommuneSelected={setCommuneSelected}
            error={error}
            fileValue={fileValue}
            setFileValue={setFileValue}
            pdfError={pdfError}
            setPdfError={setPdfError}
          />
          <Vehicles
            vehiclesData={vehiclesData}
            setVehiclesData={setVehiclesData}
            error={error}
            setError={setError}
            resolutionsSelected={resolutionsSelected}
            setResolutionsSelected={setResolutionsSelected}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="secondary"
            onClick={handleCloseModal}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleSend()}
            color="primary"
            startIcon={<CheckIcon />}
          >
            Agregar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

CreateModal.propTypes = {
  open: PropTypes.bool,
};

export default CreateModal;
