import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  ButtonGroup,
  Button,
  Typography,Grid,TextField,MenuItem,InputAdornment,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import SearchBar from "material-ui-search-bar";
import removalsActions from "../../../redux/actions/removals";

import dispositionsActions from "../../../redux/actions/dispositions";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "rut",
    numeric: false,
    disablePadding: false,
    label: "Rut",
  },
  {
    id: "typeOfTreatment",
    numeric: false,
    disablePadding: false,
    label: "Tipo de tratamiento",
  },
  {
    id: "commune",
    numeric: false,
    disablePadding: false,
    label: "Comuna",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: true,
    label: "Opciones"
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  communesData,
  typeOfTreatments,
  // setDeleteModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [rows, setRows] = useState([]);
  const [typeSearch, setTypeSearch] = useState("ALL");
  const [firstParam, setFirstParam] = useState("");
  const [secondParam, setSecondParam] = useState("");
  
  const dispositions = useSelector((state) => state.dispositions.dispositions);
  
  const [searched, setSearched] = useState("");

  React.useEffect(() => {
    if (dispositions) {
      setRows(dispositions);
    }
  }, [dispositions]);

  const openModalViewExtra = (data) => {
    // dispatch(removalsActions.setViewExtraModal(true));
    // dispatch(removalsActions.setDataExtraModal(data));
  };

  const openModalEdit = (data) => {
    dispatch(dispositionsActions.setDataModal(data));
    dispatch(dispositionsActions.setEditModal(true));
  };

  const openModalDelete = (data) => {
    dispatch(dispositionsActions.setDeleteModal(true));
    dispatch(dispositionsActions.setDataModal(data));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function Item(row, i) {
    return (
      <div key={`type-${i}`}>
        {
          typeOfTreatments.filter(
            (typeOfTreatment) => typeOfTreatment.code === row.code
          )[0].name
        }
      </div>
    )
  }

  const searchOnClick = () => {
    var data = null;
    if (typeSearch === "NAME") {
      data = {
        type: "NAME",
        firstParam,
      };
    }
    
    dispatch(removalsActions.setSearch(data));
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = dispositions.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };


  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="outlined-required"
            label="Tipo de búsqueda"
            variant="outlined"
            margin="dense"
            type="text"
            value={typeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            select
            fullWidth
          >
            <MenuItem value="ALL">Todos</MenuItem>
            <MenuItem value="NAME">Nombre</MenuItem>
            <MenuItem value="RUT">Rut</MenuItem>
            <MenuItem value="TREATMENT">Tipo de tratamiento</MenuItem>
            <MenuItem value="COMUNE">Comuna</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
        {typeSearch === "NAME" ? (
            <TextField
              id="outlined-required"
              label="Buscar"
              variant="outlined"
              margin="dense"
              type="text"
              onChange={(e) => setFirstParam(e.target.value)}
              value={firstParam}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          ) : typeSearch === "RUT" ? (
            <TextField
            id="outlined-required"
            label="Buscar"
            variant="outlined"
            margin="dense"
            type="text"
            onChange={(e) => setFirstParam(e.target.value)}
            value={firstParam}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          ) : ("")}
          </Grid>
          <Grid item xs={3}>
          {typeSearch !== "ALL" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => searchOnClick()}
              style={{ marginTop: 10 }}
              startIcon={<SearchIcon />}
              fullWidth
            >
              Buscar
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
      <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index);

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <StyledTableCell align="left">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.rut}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.typeOfTreatment.map((element, i) => Item(element, i))}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          communesData.filter(
                            (commune) => commune.code === row.commune
                          )[0].name
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ButtonGroup size="small" variant="contained">
                          <Button
                            disabled
                            color="primary"
                            // onClick={() => openModalView(row)}
                          >
                            <VisibilityIcon />
                            ver
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => openModalEdit(row)}
                          >
                            <EditIcon />
                            editar
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => openModalDelete(row)}
                          >
                            <DeleteIcon />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length ? (
            ""
          ) : (
            <Typography style={{ margin: 24 }}>
              No se encontraron resultados
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
