import React, { useState, useEffect } from "react";
import logo from "../../resources/logos/logo.png";
import logoColor from "../../resources/logos/logo-color.png";
import {
  Menu as MenuIcon,
  AccountCircle as AccountIcon,
  Brightness4 as LightIcon,
  Brightness7 as DarkIcon,
} from "@material-ui/icons";

import {
  Grid,
  Menu,
  AppBar,
  Toolbar,
  LinearProgress,
  makeStyles,
  IconButton,
  SwipeableDrawer,
  MenuItem,
} from "@material-ui/core";

import MenuBar from "./Menu";
import ProfileCard from "./ProfileCard";
import { useSelector, useDispatch } from "react-redux";
import appActions from "../../redux/actions/app";

const useStyles = makeStyles(() => ({
  mobileList: {
    width: "250px",
    height: "100%",
  },
  toolbarLogo: {
    height: "60px",
    margin: "10px",
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const switchMenu = (open) => dispatch(appActions.switchMenu(open));

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfile = Boolean(anchorEl);

  useEffect(() => setAnchorEl(null), [app.loged]);

  return (
    <AppBar
      color="transparent"
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {!app.isMobile && (
            <Grid item>
              <img
                src={app.theme === "DARK" ? logo : logoColor}
                alt="Acción Circular"
                style={{ height: 50, marginLeft: app.isMobile ? 5 : 20 }}
              />
            </Grid>
          )}
          {app.isMobile && app.loged === "LOGED" && (
            <Grid item>
              <IconButton onClick={() => switchMenu(true)}>
                <MenuIcon style={{ color: "white" }} />
              </IconButton>
              <SwipeableDrawer
                classes={{
                  paper: classes.drawer,
                }}
                open={app.isOpenMenu}
                onOpen={() => switchMenu(true)}
                onClose={() => switchMenu(false)}
              >
                <MenuBar switchMenu={switchMenu} />
              </SwipeableDrawer>
            </Grid>
          )}
          {auth.loged === "LOGED" && (
            <Grid item>
              <IconButton
                aria-label="tema"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={() =>
                  dispatch(
                    appActions.setTheme(
                      app.theme === "LIGHT" ? "DARK" : "LIGHT"
                    )
                  )
                }
              >
                {app.theme === "LIGHT" ? <LightIcon /> : <DarkIcon />}
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="inherit"
              >
                <AccountIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openProfile}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem disableGutters disableRipple disableTouchRipple>
                  <ProfileCard />
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
      </Toolbar>
      {app.loading && (
        <LinearProgress
          color="secondary"
          style={{ height: 5, marginTop: -5 }}
        />
      )}
    </AppBar>
  );
}
