import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import CloseIcon from "@material-ui/icons/Close";
import UndoIcon from "@material-ui/icons/Undo";

import Locals from "./Locals";
import { useCustomersContext } from "../../../../context/CustomersContext";

export default function ModalCreate() {
  const { setViewModalEditLocal, data, updateLocal } = useCustomersContext();
  const [status, setStatus] = React.useState(0);
  const [localData, setLocalData] = React.useState({});

  React.useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleClose = () => {
    setViewModalEditLocal(false);
  };

  const handleUpdate = () => {
    updateLocal(localData).then((_response) => {
      setViewModalEditLocal(false);
    });
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <h4>Editar local</h4>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              size="small"
              onClick={() => setLocalData(data)}
              variant="contained"
              startIcon={<UndoIcon />}
            >
              Deshacer cambios
            </Button>
          </Grid>
        </Grid>
        <Locals
          setLocalData={setLocalData}
          localData={localData}
          setStatus={setStatus}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <Button
            onClick={handleClose}
            color="primary"
            startIcon={<CloseIcon />}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            color={status ? "secondary" : "default"}
            onClick={() => handleUpdate()}
            startIcon={<CachedIcon />}
            variant="contained"
          >
            Actualizar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
