import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

export default function UserIsAuthenticated(props) {
  const auth = useSelector((state) => state.auth);
  // console.log("UserIsAuthenticated");
  var red = window.location.search;
  // console.log(auth.loged);
  // if (auth.loged === "NOT_LOGED") {
  //   return <Redirect to={"/login"+red} />;
  // } else {
  //   var wnd = window.location.pathname;
  //   if(wnd == '/login'){
  //     return <Redirect to={"/"} />;
  //   } else {
  //     return <Fragment {...props} />;
  //   }
    
  // }
  if (auth.loged === "LOGED") {
    var wnd = window.location.pathname;
    if(wnd == '/login'){
      return <Redirect to={"/"} />;
    } else {
      return <Fragment {...props} />;
    }
  } else {
    return <Redirect to={"/login"+red} />;
  }
}
