const defaultState = {
  users: [],
  dataModal: null,
  createModal: false,
  deleteModal: false,
  editModal: false,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "USERS_FETCH": {
      return { ...state, users: payload };
    }
    case "USERS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "USERS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "USERS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "USERS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    default:
      return state;
  }
}

export default reducer;
