import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { parseFlag } from "../../../../resources/residuesTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    maxWidth: 610,
  },
  title: {
    margin: theme.spacing(1),
  },
}));

export default function ViewCustomer({ materials }) {
  const classes = useStyles();
  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Material</TableCell>
          <TableCell align="right">Kilos reciclados</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {materials.map((material, i) => (
          <TableRow key={i}>
            <TableCell>{parseFlag(material._id)}</TableCell>
            <TableCell align="right">{material.quantity} Kg</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <b>TOTAL:</b>{" "}
          </TableCell>
          <TableCell align="right">
            <b>
              {materials.length
                ? materials.reduce(
                    (ac, material) => ac + material.quantity * 1,
                    0
                  )
                : 0}{" "}
              Kg
            </b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
