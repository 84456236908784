import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useAppContext } from "../../../../context/AppContext";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  imgDark: {
    width: "50%",
    marginBottom: 20,
    opacity: 0.8,
  },
  imgLight: {
    filter: "invert(100%);",
    width: "50%",
    marginBottom: 20,
    opacity: 0.6,
  },
}));
export default function Nothing({ text }) {
  const classes = useStyles();
  const { themeColor } = useAppContext();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item align="center" xs={6} md={3}>
        <img
          src={"https://image.flaticon.com/icons/svg/843/843734.svg"}
          className={
            themeColor === "light" ? classes.imgLight : classes.imgDark
          }
          alt="Aquí no hay nada"
        />
        <Typography variant="body1" color="textSecondary" align="center">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

Nothing.propTypes = {
  text: PropTypes.string.isRequired,
};
