import React, {useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VideoIcon from "@material-ui/icons/VideoLibrary"
import DeleteIcon from "@material-ui/icons/Delete";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { Button, ButtonGroup, Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
Grid, TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSelector, useDispatch } from "react-redux";
import learningActions from "../../../redux/actions/learning";
import Switch from '@material-ui/core/Switch';
import {Player} from "video-react"
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "createAt",
    numeric: false,
    disablePadding: false,
    label: "Fecha creación",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Fecha modificación",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
  
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  



  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function Tabla() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("datetimeCreated");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [rows, setRows] = React.useState([]);
  const learning = useSelector((state) => state.learning);
  const [view, setView] = React.useState(false);
  const [urlVideo, setUrlVideo] = React.useState("");
  const [update, setUpdate] = React.useState(false);
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [datosEdit, setDatosEdit] = React.useState({title: "", description: "", id: ""});

  React.useEffect(() => {
    if (learning.learning.data) {
      
      setRows(learning.learning.data);
    }
  }, [learning.learning.data]);

  /* const Click = () => {
    dispatch(learningActions.fetchDisplay(id))
  } */

  console.log(rows);

  async function handleUpload(e,id) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    console.log(id);
      try {
      const response = await dispatch(learningActions.viewDisplay(formData));
      setUrlVideo(response);
      setIsUploaded(true);
      setIsUploading(false);
      if (view){
        setView(false);
      }else {
        setView(true);
      }
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
      handleClose();
    }
}

  async function handleEdit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", datosEdit.title);
    formData.append("description", datosEdit.description);
    formData.append("id", datosEdit.id);

      try {
      const response = await dispatch(learningActions.updateLearning(formData));
      setIsUploaded(true);
      setIsUploading(false);
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
      handleClose();
    }
}

  async function handleStatus(e,status,id) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("status", status === 0 ? 1 : 0);
    formData.append("id", id);
      try {
      const response = await dispatch(learningActions.updateLearning(formData));
      setIsUploaded(true);
      setIsUploading(false);
      const start = async () => {
        dispatch(learningActions.fetchLearning());
      };
      start();
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
      handleClose();
    }
}

  const handleClose = () => {
    setView(false);
    setUpdate(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  /* const deleteReports = (data) => {
    dispatch(reportsActions.setDeleteModal(true));
    dispatch(reportsActions.setDataModal(data));
  }; */

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    
    function handleClickEdit(index){
      
      var data = rows.data[index];
      setDatosEdit({
        title: data.title,
        description: data.description,
        id: data._id
      })
      setUpdate(true);
 }

  const handleChange = e => {
    const {name, value} = e.target
    setDatosEdit({
        ...datosEdit,
        [name]: value,
    })
  } 

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
        ) : (
          ""
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </Toolbar>
    );
  };
 
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
       {/*  <EnhancedTableToolbar numSelected={selected.length} /> */}
       <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <StyledTableCell align="left">
                      {row.title}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                      {row.createdAt
                        ? new Intl.DateTimeFormat("es-CL", {
                            year: "numeric",
                            month: "numeric",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric"
                          }).format(new Date(row.createdAt))
                        : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                      {row.createdAt
                        ? new Intl.DateTimeFormat("es-CL", {
                            year: "numeric",
                            month: "numeric",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric"
                          }).format(new Date(row.createdAt))
                        : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                      {row.description}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                          <Switch
                          color="primary"
                          checked = {row.status === 1 ? true : false }
                          onClick={(e) => handleStatus(e,row.status,row._id)}
                          />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                      <ButtonGroup size="small" variant="contained">
                          <Button
                            id={row._id}
                            value={row.url}
                            onClick={(e) => handleUpload(e,row._id)}
                            color="primary"
                          >
                            <VideoIcon />
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => handleClickEdit(index)}
                          >
                            <EditIcon />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        
      </Paper>
      {view && (
      <Dialog
        open={view}
        aria-labelledby="form-dialog-title"
        scroll="body"
        maxWidth="md"
        fullWidth
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
          </Grid>
            <Grid item>
              <DialogTitle>{/* {urlVideo} */}</DialogTitle>
              <Button
                onClick={handleClose}
                color="primary"
                size="large"
              >
                <ClearOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <DialogContent style={{height: 500}}>
          
          <video controls autoPlay width="900" height="460">
              <source src={urlVideo} type="video/webm" />
          </video>
          
          </DialogContent> 
        </Dialog>
        )}
        <Dialog
            open={update}
            aria-labelledby="form-dialog-title"
            scroll="body"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Modificar Nuevo Aprendizaje</DialogTitle>
            <form>
              <DialogContent>
                <TextField
                  name="title"
                  margin="dense"
                  variant="outlined"
                  label="Titulo del video"
                  fullWidth
                  value={datosEdit.title}
                  onChange={handleChange}
                />
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="description"
                    margin="dense"
                    variant="outlined"
                    label="Descripción del video"
                    placeholder="Escribe aquí toda la información que consideres relevante."
                    fullWidth
                    rows={6}
                    multiline
                    value={datosEdit.description}
                    onChange={handleChange}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="default"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                   variant="contained" 
                   color="success"
                   onClick={(e) => handleEdit(e)}>
                    Aceptar
                  </Button>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
    </div>
    
  );
}
