import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Card,
  CardContent,
  Tooltip,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import validator from "email-validator";
import { format } from "rut.js";
import axios from "axios";

import materialsList from "../../../../resources/materials";
import functions from "../../../../resources/functions";

export default function General({
  formData,
  setFormData,
  updateData,
  communes,
  communeSelected,
  setCommuneSelected,
  treatmentType,
  setTreatmentType,
  typeOfTreatmentsOptions,
  typeOfTreatmentSelected,
  setTypeOfTreatmentSelected,
  error,
}) {

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isManualPosition, setIsManualPosition] = useState(false);
  const [manualPosition, setManualPosition] = useState("");
  const [errorManualPosition, setErrorManualPosition] = useState(false);

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };

  const materials = materialsList.materials;
  const loading = open && options.length === 0 && formData.address !== "";

  React.useEffect(() => {
    if (formData.address === "") {
      return undefined;
    }
    (async () => {
      const endpoint = process.env.REACT_APP_API_MAPBOX;
      const token = process.env.REACT_APP_MAPGL_API_KEY;

      let timeout = 0
      if (timeout) clearTimeout(timeout);
      const query = encodeURIComponent(formData.address + ".json");
      const previus = formData.address;

      if (previus !== formData.address) {
        return undefined;
      }
      timeout = setTimeout(async () => {
        const response = await axios.get(endpoint + query, {
          params: { access_token: token },
        });
        const payload = await response.data.features;
        setOptions(payload.map((result) => {
          if (result.length === 0) return
          return {
            name: result.place_name,
            coords: {
              lat: result.center[1],
              long: result.center[0],
            },
            address: result.place_name,
          };
        })
        );
      }, 0);
    })();
  }, [formData.address])

  const formatPhone = (phone) => {
    return functions.formatPhone(phone);
  };

  const formatRuc = (rut) => {
    return format(rut);
  };

  const handleRegProvComsForm = (event) => {
    setCommuneSelected(event);
    setFormData({
      ...formData,
      commune: event.code,
      region: event.regCode,
      province: event.provCode
    })
  }

  const handleTypeOfTreatment = (event) => {
    setTypeOfTreatmentSelected(...event);
    setFormData({
      ...formData,
      typeOfTreatment: [...event]
    })
  }

  const handleAddress = (k) => {
    setFormData({
      ...formData,
      address: k.address,
      position: {
        latitude: k.coords.lat,
        longitude: k.coords.long
      }
    })
  }

  const handleTreatmentType = (value) => {
    setTreatmentType(value)
    setFormData({
      ...formData,
      typeOfTreatment: []
    })
  }

  const handleManualPositions = () => {
    if (manualPosition.length < 22) { return setErrorManualPosition(true) }
    else {
      let latitudeAux = manualPosition.toString().slice(0, 10);
      let longitudeAux = manualPosition.toString().slice(11, 22);
      setFormData({
        ...formData,
        position: {
          latitude: parseFloat(latitudeAux),
          longitude: parseFloat(longitudeAux)
        }
      })
    }
  }

  const toggeMaterial = (material) => {
    setFormData({
      ...formData,
      materialsID: {
        ...formData.materialsID,
        [material]: !formData.materialsID[material],
      },
    });
  };

  function ItemCommune(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Coumna"
          value={formData.commune}
          error={error && !formData.commune}
        />
      </div>
    );
  }

  function ItemTypeOfTreatment(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Tratamientos"
          error={error && !formData.typeOfTreatment}
        />
      </div>
    );
  }

  function ItemAddress(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Dirección"
          onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={!formData.address && error}
        />
      </div>
    );
  }

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos de la empresa</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre empresa"
            value={formData.name}
            onChange={(event) => updateData(event.target.value, "name")}
            error={error && !formData.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Rut"
            inputProps={{
              maxLength: 12
            }}
            value={formatRuc(formData.rut)}
            onChange={(event) => updateData(event.target.value, "rut")}
            error={error && !formData.rut}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre de contacto"
            value={formData.contactName}
            onChange={(event) => updateData(event.target.value, "contactName")}
            error={error && !formData.contactName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Mail"
            value={formData.email}
            onChange={(event) => updateData(event.target.value, "email")}
            error={!validator.validate(formData.email) && error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Teléfono"
            value={formatPhone(formData.phone)}
            onChange={(event) => updateData(event.target.value, "phone")}
            error={error && !formData.phone}
          />
        </Grid>
        <Grid item xs={6}>
          {!isManualPosition ?
            (<Autocomplete
              id="asynchronous-demo"
              open={open}
              onOpen={() => { setOpen(true) }}
              onClose={() => { setOpen(false) }}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              options={options}
              loading={loading}
              renderInput={(params) => ItemAddress(params)}
              onChange={(_, k) => handleAddress(k)}
            />)
            :
            (<TextField
              {...style}
              name="address"
              label="Dirección"
              value={formData.address}
              onChange={(event) => updateData(event.target.value, "address")}
              error={!formData.address && error}
            />)
          }
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="commune"
            options={communes}
            disableClearable
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            value={communeSelected}
            renderInput={(params) => ItemCommune(params)}
            onChange={(_, k) => handleRegProvComsForm(k)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            select
            label="Tipo de tratamiento"
            value={treatmentType}
            onChange={(event) => handleTreatmentType(event.target.value)}
          >
            <MenuItem value="TOT_ELI">Eliminación</MenuItem>
            <MenuItem value="TOT_VAL">Valorización</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="multiple=typeOfTreatment"
            key={treatmentType}
            options={typeOfTreatmentsOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            filterSelectedOptions
            value={typeOfTreatmentSelected}
            renderInput={(params) => ItemTypeOfTreatment(params)}
            onChange={(_, k) => handleTypeOfTreatment(k)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Agregar coordenadas manual"
            control={
              <Checkbox
                checked={isManualPosition}
                onChange={(event) => setIsManualPosition(event.target.checked)}
              />
            }
          />
        </Grid>
        {isManualPosition &&
          <Grid container spacing={1}>
            <Grid item md={6} sm={6}>
              <TextField
                name="manualCoordinates"
                label="Latitud y longitud"
                placeholder="-33.436988,-70.634391"
                onChange={(e) => setManualPosition(e.target.value)}
                error={(!formData.position && !isManualPosition) && errorManualPosition}
                {...style}
              />
            </Grid>
            <Grid item md={6} sm={6}>
              <Button
                onClick={handleManualPositions}
                color={"primary"}
              >
                actualizar coordenadas
              </Button>
            </Grid>
          </Grid>
        }
        <hr></hr>
        <Grid item xs={12}>
          <h5>Materiales que recibe</h5>
        </Grid>
        {Object.keys(materials).map((key) => (
          <Grid item sm={2} xs={4} key={"material_modal_create_" + key}>
            <Tooltip title={materials[key].name}>
              <Box boxShadow={3}>
                <Card
                  style={{ border: formData.materialsID[key] ? "3px solid #303f9f" : "" }}
                  onClick={() => toggeMaterial(key)}
                >
                  <CardContent>
                    <img
                      src={materials[key].images["dark"]}
                      alt={materials[key].name}
                      style={{ maxWidth: "100%", opacity: "0.4" }}
                    />
                  </CardContent>
                  <hr color={materials[key].color} style={{ height: 3 }} />
                </Card>
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
