import React from "react";
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol } from "mdbreact";
import NumberFormat from "react-number-format";

function AdminCardSection2({ data, uf }) {
  const [payment, setPayment] = React.useState(0);
  const [completes, setCompletes] = React.useState(0);
  const [profit, setProfit] = React.useState(0);
  const [suscriptions, setSuscriptions] = React.useState(0);
  React.useEffect(() => {
    // if (data) {
    //   let aux = 0;
    //   let aux2 = 0;
    //   let aux3 = 0;
    //   let aux4 = 0;
    //   data.removalsPerLocal.forEach((element, i) => {
    //     aux += element.total;
    //     aux2 += element._id.suscription * uf - element.total;
    //     aux3 += element._id.suscription * uf;
    //     aux4 += element.quantity;
    //   });
      setCompletes(350);
      setSuscriptions(2400000);
      setProfit(2400000);
      setPayment(1240000);
    // }
  // }, [data, uf]);
  },[]);

  return (
    <MDBRow className="mb-4">
      <MDBCol xl="3" md="6" className="mb-3">
        <MDBCard color="success-color" className="classic-admin-card">
          <MDBCardBody>
            <div className="float-right">
              <MDBIcon icon="chart-pie" />
            </div>
            <p className="white-text">RETIROS REALIZADOS</p>
            <h4>
              <strong>{completes}</strong>
            </h4>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-3">
        <MDBCard color="info-color" className="classic-admin-card">
          <MDBCardBody>
            <div className="float-right">
              <MDBIcon far icon="money-bill-alt" />
            </div>
            <p className="white-text">INGRESO SUSCRIPCIONES</p>
            <h4>
              <strong>
                <NumberFormat
                  value={Math.round(suscriptions, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </strong>
            </h4>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-3">
        <MDBCard color="warning-color" className="classic-admin-card">
          <MDBCardBody>
            <div className="float-right">
              <MDBIcon far icon="money-bill-alt" />
            </div>
            <p className="white-text">PAGOS POR RETIROS</p>
            <h4>
              <strong>
                <NumberFormat
                  value={Math.round(payment, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </strong>
            </h4>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-3">
        <MDBCard
          color={profit >= 0 ? "success-color" : "danger-color"}
          className="classic-admin-card"
        >
          <MDBCardBody>
            <div className="float-right">
              <MDBIcon far icon="money-bill-alt" />
            </div>
            <p className="white-text">UTILIDAD</p>
            <h4>
              <strong>
                <NumberFormat
                  value={Math.round(profit, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </strong>
            </h4>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}

export default AdminCardSection2;
