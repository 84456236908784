import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  // Button,
} from "@material-ui/core";
// import DeleteIcon from "@material-ui/icons/Delete";

export default function TableVehicles({
  vehiclesFormData,
  // deleteVehicle,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(vehiclesFormData);
  }, [vehiclesFormData]);

  function Item(resolution, k) {
    return (
      <div key={`res-${k}`}>
        {resolution === "RP" ? "Residuos Peligrosos" : "Residuos No Peligrosos"}
      </div>
    );
  };

  return (
    <div>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Marca</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Patente</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Volumen Max</TableCell>
            <TableCell>Unidad</TableCell>
            <TableCell>Resolución(es)</TableCell>
            {/* <TableCell>Opciones</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((element, i) => (
            <TableRow key={`row-${i}`}>
              <TableCell>
                {element.brand ? element.brand : "-"}
              </TableCell>
              <TableCell>
                {element.model ? element.model : "-"}
              </TableCell>
              <TableCell>
                {element.licensePlate ? element.licensePlate : "-"}
              </TableCell>
              <TableCell>
                {element.type ? element.type : "-"}
              </TableCell>
              <TableCell>
                {element.maxVolume ? element.maxVolume : "-"}
              </TableCell>
			  <TableCell>
                {element.typeUnit ? element.typeUnit : "-"}
              </TableCell>
              <TableCell>{element.resolutions.length ? element.resolutions.map((resolution, k) => Item(resolution, k)) : "-"}</TableCell>
              {/* <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => deleteVehicle(i)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
