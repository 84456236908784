import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  makeStyles,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import colorLogo from "../../resources/images/logo-color.png";
import SecurityIcon from "@material-ui/icons/Security";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth";
import Axios from "../../api/Axios";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    backgroundColor: "white",
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
}));

export default function Login() {
  // console.log("LOGIN");
  const styles = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [token, setTkn] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth.loginStatus.error) {
      setTimeout(() => {
        dispatch({
          type: "AUTH_SET_LOGIN_STATUS",
          payload: {
            error: false,
            step: "READY",
            message: "",
          },
        });
      }, 1500);
    }
  }, [auth.loginStatus.error]);

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      dispatch(authActions.login({ ...formData }));
    }
  };

  window.onload = function() {
    // alert("orlaff");
    // console.log("onload");
    var wnd = window.location.pathname;
    var wndL = window.location;
  
    if(wnd == '/login'){
      var red = window.location.search;
  
      if(red.length > 0){
        goReset();
        var tkn = red.replace("?", "");
        console.log(tkn);
        setTkn(tkn);
      }
    }

    // passwordReset
    // token
    // rPasswordReset
  
  };

  async function changepass() {

    // try {
      var password = document.getElementById("passwordReset").value;
      var pass2 = document.getElementById("rPasswordReset").value;
      // var token = document.getElementById("tokenReset").value;
      var red = window.location.origin + "/login";
  
      console.log(password);
      console.log(pass2);
  
      if(password.length > 0 || pass2.length > 0 ){
        if(password == pass2){
          let response = await Axios.post("/auth/reset_password", { token, password });
          console.log(response);
          console.log(response.data.success);
  
          if(response.data.success == 1){
            alert("Contraseña cambiada con exito.");
            // backLogin();
            window.location.href = red;
          } else {
            alert(response.data.message);
          }
  
        } else {
          alert("Las contraseñas deben ser iguales");
        }
      } else {
        alert("Todos los campos son obligatorios");
      } 
  }

  const goReset = () => {
    console.log("RESET PASSWORD");
    document.getElementById("divLog").style.display = 'none';
    document.getElementById("divRecovery").style.display = 'none';
    document.getElementById("divResetPass").style.display = 'block';
  }; 

  const goRecovery = () => {
    console.log("RECOVERY PASSWORD");
    document.getElementById("divResetPass").style.display = 'none';
    document.getElementById("divLog").style.display = 'none';
    document.getElementById("divRecovery").style.display = 'block';
  }; 
  
  const backLogin = () => {
    console.log("LOGIN");
    document.getElementById("divResetPass").style.display = 'none';
    document.getElementById("divRecovery").style.display = 'none';
    document.getElementById("divLog").style.display = 'block';
    
  };

  const recovery  = async () => {
    console.log("recovery");
    console.log(document.getElementById("emailRecovery").value);
    try {
      var email = document.getElementById("emailRecovery").value;
      var emailR = document.getElementById("rEmailRecovery").value;
      var red = window.location.origin + "/login";
      console.log(email);
      console.log(emailR);
      
      if(email.length > 0 || emailR.length > 0){
        if(validateEmail(email) && validateEmail(emailR)){
          if(email == emailR){
            console.log("post");
            let response = await Axios.post("/auth/recoverAdmin", { email });
            console.log(response);
              if(response.data.success == 1){
                alert("Se ha enviado las instrucciones a tu correo.");
                // backLogin();
                // var red = window.location.origin + "/login";
                window.location.href = red;
              } else {
                alert(response.data.message);
              }      
          } else {
            alert("Los correos ingresados son distintos.");
          }
        } else {
          alert("Ingresa un correo valido.");
        }

      } else {
        alert("Todos los campos son obligatorios.");
      }
  
    } catch (error) {
      console.log(error);
      alert("Hubo un problema al recuperar tu contraseña.");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <React.Fragment>
    <div
      id="divLog"
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img
                src={colorLogo}
                alt="Acción Circular"
                style={{ height: 100 }}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="h6">Administrador</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.email}
                error={auth.loginStatus.error}
                name="email"
                label="Correo"
                autoComplete="email"
                autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.password}
                error={auth.loginStatus.error}
                name="password"
                type="password"
                label="Contraseña"
                variant="outlined"
                autoComplete="current-password"
                onChange={updateData}
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                variant={
                  auth.loginStatus.step === "READY" ? "contained" : "outlined"
                }
                onClick={() => dispatch(authActions.login({ ...formData }))}
                startIcon={<ExitToAppIcon />}
              >
                Entrar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SecurityIcon />}
                // disabled
                onClick={goRecovery}
              >
                Recuperar contraseña
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
    <div
      id="divRecovery"
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "none"
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img
                src={colorLogo}
                alt="Acción Circular"
                style={{ height: 100 }}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="h6">Administrador</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // value={formData.email}
                // error={auth.loginStatus.error}
                id="emailRecovery"
                name="emailRecovery"
                label="Correo"
                autoComplete="email"
                autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                // onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // value={formData.email}
                // error={auth.loginStatus.error}
                id="rEmailRecovery"
                name="rEmailRecovery"
                label="Repite el correo"
                autoComplete="email"
                autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                // onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                // variant={
                //   auth.loginStatus.step === "READY" ? "contained" : "outlined"
                // }
                variant={"contained"}
                onClick={recovery}
                startIcon={<ExitToAppIcon />}
              >
                Recuperar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SecurityIcon />}
                // disabled
                onClick={backLogin}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
    <div
      id="divResetPass"
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "none"
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img
                src={colorLogo}
                alt="Acción Circular"
                style={{ height: 100 }}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="h6">Administrador</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="hidden"
                name="tokenReset"
                id="tokenReset"
              />
              <TextField
                // value={formData.email}
                // error={auth.loginStatus.error}
                id="passwordReset"
                name="passwordReset"
                label="Password"
                type="password"
                // autoComplete="email"
                // autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                // onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // value={formData.email}
                // error={auth.loginStatus.error}
                id="rPasswordReset"
                name="rPasswordReset"
                label="Repite contraseña"
                type="password"
                // autoComplete="email"
                // autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                // onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                // variant={
                //   auth.loginStatus.step === "READY" ? "contained" : "outlined"
                // }
                variant={"contained"}
                onClick={changepass}
                startIcon={<ExitToAppIcon />}
              >
                Cambiar contraseña
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SecurityIcon />}
                // disabled
                onClick={backLogin}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
    </React.Fragment>
  );
}
