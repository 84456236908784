import React from "react";
import { Grid, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import validator from "email-validator";
import { format } from "rut.js";

import functions from "../../../../resources/functions";

export default function General({
  formData,
  setFormData,
  updateData,
  communes,
  communeSelected,
  setCommuneSelected,
  error,
  fileValue,
  setFileValue,
  pdfError,
  setPdfError,
  pngError,
  setPngError,
}) {

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };

  const formatPhone = (phone) => {
    return functions.formatPhone(phone);
  };

  const formatRuc = (rut) => {
    return format(rut);
  };

  const handleRegProvComsForm = (event) => {
    setCommuneSelected(event);
    setFormData({
      ...formData,
      commune: event.code,
      region: event.regCode,
      province: event.provCode
    })
  };

  const handleLoadFile = (event) => {
    var files = event.target.files;
    var file = null;

    if (files[0].type === "application/pdf") {
      setPdfError(false);
      let fileReader = new FileReader();
      setFileValue(event.target.value);
      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        setFormData({ ...formData, file });
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      setFileValue(null);
      setPdfError(true);
      setPngError(true);
    }

    if (files[0].type === "application/png"){
      setPngError(false);
      let fileReader = new FileReader();
      setFileValue(event.target.value);
      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        setFormData({ ...formData, file });
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      setFileValue(null);
      setPngError(true);
    }

  };

  function Item(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Comuna"
          value={formData.commune}
          error={error && !formData.commune}
        />
      </div>
    );
  };

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos de empresa</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre de la empresa"
            value={formData.company}
            onChange={(event) => updateData(event.target.value, "company")}
            error={error && !formData.company}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Rut de la empresa"
            value={formatRuc(formData.rutEnterprise)}
            onChange={(event) => updateData(event.target.value, "rutEnterprise")}
            error={error && !formData.rutEnterprise}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Dirección"
            value={formData.address}
            onChange={(event) => updateData(event.target.value, "address")}
            error={error && !formData.address}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="commune"
            options={communes}
            disableClearable
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            value={communeSelected}
            renderInput={(params) => Item(params)}
            onChange={(_, k) => handleRegProvComsForm(k)}
          />
        </Grid>

        <Grid item xs={12}>
          <hr></hr>
          <h5>Datos de contacto</h5>
        </Grid>

        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre"
            value={formData.name}
            onChange={(event) => updateData(event.target.value, "name")}
            error={error && !formData.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Apellido"
            value={formData.lastName}
            onChange={(event) => updateData(event.target.value, "lastName")}
            error={error && !formData.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Rut"
            value={formatRuc(formData.rut)}
            onChange={(event) => updateData(event.target.value, "rut")}
            error={error && !formData.rut}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Email"
            value={formData.email}
            onChange={(event) => updateData(event.target.value, "email")}
            error={error && !validator.validate(formData.email)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Teléfono"
            value={formatPhone(formData.phone)}
            onChange={(event) => updateData(event.target.value, "phone")}
            error={error && !formData.phone}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="¿Tiene certificación?"
            control={
              <Checkbox
                checked={formData.certification}
                style={{ color: formData.certification && "#00C851" }}
                onChange={(event) =>
                  updateData(event.target.checked, "certification")
                }
                value={formData.certification}
              />
            }
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            {...style}
            label="Certificación"
            value={formData.certificationPDF ? formData.certificationPDF : fileValue}
            onChange={handleLoadFile}
            type={formData.certificationPDF ? "text" : "file"}
            accept="pdf"
            accept="png"
            disabled={!formData.certification}
            error={pdfError && pngError}
            helperText={pdfError || pngError && "Debe ser un archivo pdf/png."}
            onClick={formData.certificationPDF ? () => window.open(formData.certificationPDF) : null}
          />
        </Grid>
      </Grid>
    </div>
  );
}
