import React from "react";
import { Grid, Card, CardContent, TextField } from "@material-ui/core";

export default function GeneralData({ mainData, locals }) {
  const style = {
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      readOnly: true,
    },
    fullWidth: true,
    variant: "outlined",
    margin: "dense",
  };
  return (
    <Card>
      <CardContent>
        <h4 style={{ textAlign: "center" }}>Datos generales</h4>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Cantidad de locales"
              value={locals.length}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Suscripción total"
              value={"deprecado"}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Nombre empresa"
              value={mainData.brand}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Razón social"
              value={mainData.businessName}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Rut" value={mainData.rut} {...style} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Giro"
              value={mainData.businessActivity}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Domicilio de la sociedad"
              value={mainData.addressSociety}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Representante legal"
              value={mainData.legalRepresentName}
              {...style}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Rut representante legal"
              value={mainData.legalRepresentRut}
              {...style}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
