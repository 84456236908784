import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const getSuscriptions = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/adminTool/suscriptions", {
        params: {},
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

const createSuscription = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        apiUrl + "/adminTool/suscriptions",
        { ...data },
        {
          headers: {
            authorization: window.localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export { getSuscriptions, createSuscription };
