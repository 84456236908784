const setIsMobile = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_IS_MOBILE", payload: value });
  };
};
const switchMenu = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SWITCH_MENU", payload: value });
  };
};
const setTheme = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_THEME", payload: value });
  };
};
const setLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_LOADING", payload: value });
  };
};
export default {
  setIsMobile,
  switchMenu,
  setTheme,
  setLoading,
};
