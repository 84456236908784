import axios from "axios";
import notifiesActions from "./notifies";

const fetchUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/users");
      dispatch({ type: "USERS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return false;
    }
  };
};

const deleteUser = (userID) => {
  return async (dispatch) => {
    try {
      await axios.delete("/adminTool/users", { params: { userID } });
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_SUCCESS"));
      dispatch(fetchUsers());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_FAIL"));
      return false;
    }
  };
};

const createUser = (data) => {
  return async (dispatch) => {
    try {
      await axios.post("/adminTool/users", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      return false;
    }
  };
};

const assignUserToEntity = (data) => {
  return async (dispatch) => {
    try {
      if (data.type === "TRANSPORTER") {
        data.entityID = data.transporterID;
      }
      await axios.post("/adminTool/users/assignUserToEntity", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_DELETE_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_EDIT_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_DATA_MODAL", payload: value });
};

const fetchTransporterList = () => {
  return async (dispatch) => {
    try {
      /* debugger; */
      const response = await axios.get("/adminTool/transporterList",{
        headers: {
          authorization: window.localStorage.getItem("token"),
      },
      });
      console.log(response)
      dispatch({ type: "TLIST_FETCH", payload: response.data });
      return response.data;
    } catch (e) {
      return false;
    }
  };
};

export default {
  fetchUsers,
  deleteUser,
  createUser,
  assignUserToEntity,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setDataModal,
  fetchTransporterList
};
