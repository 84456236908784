import React, { Fragment } from "react";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EcoIcon from "@material-ui/icons/Eco";
import { Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import removalsActions from "../../../../redux/actions/removals";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ResponsiveDialog({ formData, view }) {
  const classes = useStyles();
  const removals = useSelector((state) => state.removals);
  const dataModal = removals.dataModal;
  
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>

		  {
              removals.selector === "COMPLETE"  ? (
                <Typography variant="h5">Validar retiro</Typography>
              ) : removals.selector === "INCOMPLETE"  ? (
                <Typography variant="h5">Actualizar retiro</Typography>
              ) : removals.selector === "SUSCRIPTION" || removals.selector === "EXTRA" || removals.selector === "SENSOR"? (
                <Typography variant="h5">Asignar retiro</Typography>
              ) :(
				<Typography variant="h5">Agregar retiro</Typography>
			  )
		  }
		  
        </Grid>
        <Grid item>
          <LocalShippingIcon
            style={{ color: formData.transporterID ? "00C851" : "#BDBDBD" }}
          />{" "}
          <AttachMoneyIcon
            style={{ color: formData.payment ? "00C851" : "#BDBDBD" }}
          />{" "}
          <EcoIcon
            style={{
              color: formData.materialsIcon ? "00C851" : "#BDBDBD",
            }}
          />{" "}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      {
		  view.alert && (
		  
        <Fragment>
		
          {   
			  !formData.localID ? (
            <Alert severity="error" variant="filled">
              <AlertTitle>
                Debes mínimo seleccionar una sucursal para el retiro
              </AlertTitle>
            </Alert>
          ) : formData.transporterID &&
            formData.payment &&
            formData.materialsIcon && removals.selector != "COMPLETE" ? (
            <Alert severity="success" variant="filled">
              <AlertTitle>
                Este retiro quedará en el apartado de retiros completos
              </AlertTitle>
            </Alert>
          ) : !formData.transporterID &&
            !formData.payment &&
            !formData.materialsIcon && removals.selector != "INCOMPLETE" && dataModal ?(
            <Alert severity="info" variant="filled">
              <AlertTitle>
                Este retiro quedará en el apartado de retiros incompletos
              </AlertTitle>
            </Alert>
          ) : !dataModal  ?(
			<Alert severity="info" variant="filled">
              <AlertTitle>
                Este retiro quedará en el apartado de retiros incompletos
              </AlertTitle>
            </Alert>
		  ):null
		  }
        </Fragment>
      )}
    </div>
  );
}
