import React, { useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import UsersList from "./UsersList";
import ModalCreate from "./ModalCreate";
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";

import { useSelector, useDispatch } from "react-redux";
import usersActions from "../../../redux/actions/users";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Removals() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    const start = async () => {
      dispatch(usersActions.fetchUsers());
    };
    start();
  }, []);

  return (
    <Paper className={classes.root}>
      <ModalCreate />
      <ModalDelete />
      <ModalEdit />
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">Usuarios</Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => dispatch(usersActions.setCreateModal(true))}
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
              >
                Agregar usuario
              </Button>
            </Grid>
          </Grid>
          <hr></hr>
          <UsersList />
        </CardContent>
      </Card>
    </Paper>
  );
}

export default Removals;
