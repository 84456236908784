import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import usersActions from "../../../redux/actions/users";

export default function ModalDelete() {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  useEffect(() => {
    if (users.dataModal) {
      setData({
        name: users.dataModal.name + " " + users.dataModal.lastName,
        email: users.dataModal.email,
        type: users.dataModal.type,
        entityID: users.dataModal.entityID,
      });
    }
  }, [users.dataModal]);

  const handleClose = () => {
    dispatch(usersActions.setDataModal(null));
    dispatch(usersActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    dispatch(usersActions.deleteUser(users.dataModal._id));
    handleClose();
  };

  return (
    <Dialog
      open={users.deleteModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Eliminar usuario</Typography>
            <hr></hr>
            <Typography variant="body1">
              ¿Seguro que quieres eliminar el usuario?{" "}
              <strong>Esta acción no puede deshacerse.</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Nombre: {data.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">E-mail: {data.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Tipo: {data.type}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Empresa / local:{" "}
              {data.type === "LOCAL"
                ? data.entityID?.name
                : data.type === "CUSTOMER"
                ? data.entityID?.brand
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button color="secondary" onClick={() => handleClose()}>
            No, salir
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Sí, eliminar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
