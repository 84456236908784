import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function TableVehicles({ vehicles, deleteVehicle }) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(vehicles);
  }, [vehicles]);

  function Item(resolution, k) {
    return (
      <div key={`res-${k}`}>
        {resolution === "RP" ? "Residuos Peligrosos" : "Residuos No Peligrosos"}
      </div>
    );
  };

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Marca</TableCell>
            <TableCell>Modelo</TableCell>
            <TableCell>Patente</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Volumen Max</TableCell>
            <TableCell>Unidades</TableCell>
            <TableCell>Resolución(es)</TableCell>
            <TableCell>Opciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((element, i) => (
            <TableRow key={i}>
              <TableCell>{element.brand ? element.brand : "-"}</TableCell>
              <TableCell>{element.model ? element.model : "-"}</TableCell>
              <TableCell>{element.licensePlate ? element.licensePlate : "-"}</TableCell>
              <TableCell>{element.type ? element.type : "-"}</TableCell>
              <TableCell>{element.maxVolume ? element.maxVolume : "-"}</TableCell>
              <TableCell>{element.typeUnit ? element.typeUnit : "-"}</TableCell>
              <TableCell>{element.resolutions && element.resolutions.map((resolution, k) => Item(resolution, k))}</TableCell>
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => deleteVehicle(i)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
