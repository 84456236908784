import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useAppContext } from "../../context/AppContext";
import { useMediaQuery, useTheme } from "@material-ui/core";

export default function Header({ title }) {
  const theme = useTheme();
  const { headerData } = useAppContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  if (!title) {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {isMobile ? (
          <Grid item>
            <Typography variant="h4" component="h2">
              {headerData.title}
            </Typography>
            <Typography variant="h5" component="h2">
              {headerData.subtitle}
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Typography variant="h4" component="h5">
              {headerData.title}
            </Typography>
            <Typography variant="h5" component="h5">
              {headerData.subtitle}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid item>{title}</Grid>
      </Grid>
    );
  }
}
