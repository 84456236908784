import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  Grid,
  Button,
  ButtonGroup,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector, useDispatch } from "react-redux";
import reportsActions from "../../../../redux/actions/reports";

const now = new Date();

function ResponsiveDialog() {
  const reports = useSelector((state) => state.reports);
  const utils = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    localID: null,
    datetimeInit: now,
    datetimeFinish: now,
    month: now.getMonth(),
    year: now.getFullYear(),
    download: false,
  });

  const [materials, setMaterials] = useState({
    cel: 0,
    plastic: 0,
    glass: 0,
    aluminium: 0,
    metals: 0,
    tetrapak: 0,
    organics: 0,
    electronics: 0,
    textils: 0,
    wood:0,
    paper:0,
    paperboard:0,
    pet:0,
    film:0,
    oil:0,
    others:0,
  });

  const [status, setStatus] = useState(0);
  const [removals, setRemovals] = useState([]);

  useEffect(() => {
    const loadData = async () => {
		
      dispatch(reportsActions.loadDataCreateExcelReport(formData));
    };
    loadData();
  }, [formData.localID, formData.datetimeInit, formData.datetimeFinish]);

  useEffect(() => {
    setFormData({
      ...formData,
      datetimeInit: new Date(formData.year, formData.month, 1),
      datetimeFinish: new Date(
        formData.year,
        formData.month * 1 + 1,
        0,
        23,
        59,
        59
      ),
    });
  }, [formData.year, formData.month]);

  useEffect(() => {
    if (reports.dataModal && reports.createModal) {
      setRemovals(reports.dataModal.removals);
      let aux = {};
      reports.dataModal.data.map((material) => {
        aux[material.material.toLowerCase()] = material.quantity;
      });
      setMaterials({
        ...aux,
      });
    }
  }, [reports.dataModal]);

  function handleClose() {
    setStatus(0);
    dispatch(reportsActions.setCreateExcelModal(false));
  }

  async function handleSuccess() {
	  
    if (formData.localID !== null) {
      try {
        setStatus(4);
		
        const response = await dispatch(reportsActions.createExcelReport(formData));
        if (response && formData.download) {
          const downloadUrl = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
		  console.log(response);
          link.href = response.data;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
          handleClose();
        } else {
          handleClose();
        }
      } catch (e) {
        handleClose();
      }
    } else {
      setStatus(2);
    }
  }

  return (
    <Dialog open={reports.createModal} scroll={"body"}>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">Crear reporte</Typography>
          </Grid>
          <Grid item>
            {status === 4 ? <CircularProgress size={24} disableShrink /> : ""}
          </Grid>
        </Grid>

        <hr></hr>
        <Typography variant="h6">General</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Sede / Sucursal"
              variant="outlined"
              margin="dense"
              value={formData.localID}
              onChange={(e) =>
                setFormData({ ...formData, localID: e.target.value })
              }
              select
              fullWidth
              error={!!(status === 2 && formData.localID === "EMPTY")}
            >
              <MenuItem value={"EMPTY"}>Selecciona un local</MenuItem>
              {utils.locals.map((local, i) => (
                <MenuItem key={i} value={local._id}>
                  {local.customerID.brand} {local.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Mes"
              variant="outlined"
              margin="dense"
              value={formData.month}
              onChange={(e) =>
                setFormData({ ...formData, month: e.target.value })
              }
              select
              fullWidth
            >
              <MenuItem value="0">Enero</MenuItem>
              <MenuItem value="1">Febrero</MenuItem>
              <MenuItem value="2">Marzo</MenuItem>
              <MenuItem value="3">Abril</MenuItem>
              <MenuItem value="4">Mayo</MenuItem>
              <MenuItem value="5">Junio</MenuItem>
              <MenuItem value="6">Julio</MenuItem>
              <MenuItem value="7">Agosto</MenuItem>
              <MenuItem value="8">Septiembre</MenuItem>
              <MenuItem value="9">Octubre</MenuItem>
              <MenuItem value="10">Noviembre</MenuItem>
              <MenuItem value="11">Diciembre</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Año"
              variant="outlined"
              margin="dense"
              value={formData.year}
              onChange={(e) =>
                setFormData({ ...formData, year: e.target.value })
              }
              select
              fullWidth
            >
              <MenuItem value="2019">2019</MenuItem>
              <MenuItem value="2020">2020</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <hr></hr>
        <Typography variant="body1">
          Retiros realizados en el mes: {removals.length}
        </Typography>
        <hr></hr>
        <Typography variant="h6">
          Materiales en kilogramos registrados en el mes
        </Typography>
        <hr />
        <Grid container spacing={2}>
          {Object.keys(utils.materials).map((material) => (
            <Grid item xs={3} key={material + "_material"}>
              <Typography variant="body2">
                {utils.materials[material].name}:
              </Typography>
              <Typography variant="body2">{materials[material]} kg</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <hr />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.activate}
                onChange={(e) =>
                  setFormData({ ...formData, download: e.target.checked })
                }
                color="primary"
              />
            }
            label="Descargar informe al terminar"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            onClick={handleClose}
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={status !== 4 ? handleSuccess : null}
            color={formData.localID === "EMPTY" ? "default" : "primary"}
            startIcon={<CheckIcon />}
          >
            Aceptar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsiveDialog;
